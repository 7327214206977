<template>
  <div>
    <Navbar
      :currentSwipedUser="currentSwipedUser"
      :notificationArray="notificationArray"
      @navbaremit="notificationArray = []"
    />
    <v-main>
      <router-view :currentSwipedUser="currentSwipedUser" />
    </v-main>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
export default {
  components: { Navbar },
  data: () => ({
    is_connected: true,
    ConnectionSwipes: null,
    setIntervalObject: {},
    currentSwipedUser: {},
    notificationArray: [],
  }),
  created() {
    this.get_recent_swipe();
    this.CheckInternet();
  },
  methods: {
    CheckInternet() {
      var self = this;
      self.setIntervalObject = setInterval(function () {
        if (navigator.onLine == false || self.is_connected == false) {
          self.ConnectionSwipes.close();
          self.get_recent_swipe();
        }
      }, 3000);
    },
    get_recent_swipe() {
      var self = this;
      self.ConnectionSwipes = new WebSocket(
        `wss://wkg3htcdmb.execute-api.ap-south-1.amazonaws.com/production?organisation_id=${this.$store.getters.get_org_details[0].organisation_id}`
      );
      self.ConnectionSwipes.onopen = function () {
        self.is_connected = true;
      };
      self.ConnectionSwipes.onclose = function () {
        self.is_connected = false;
      };
      self.ConnectionSwipes.onerror = function () {
        self.is_connected = false;
      };
      self.ConnectionSwipes.onmessage = function (event) {
        // console.log(event);
        self.currentSwipedUser = {};
        self.currentSwipedUser = JSON.parse(event.data);
        // console.log("asdasdgasyu", self.currentSwipedUser);
        self.notificationArray.push({
          icon:
            self.currentSwipedUser.source == "WHATSAPP"
              ? "mdi-whatsapp"
              : self.currentSwipedUser.source == "CAMERA"
              ? "mdi-camera"
              : self.currentSwipedUser.source == "RESWIPE"
              ? "mdi-redo-variant"
              : self.currentSwipedUser.source == "DATACORRECTION"
              ? "mdi-human-edit"
              : self.currentSwipedUser.source == "PROXY"
              ? "mdi-odnoklassniki"
              : self.currentSwipedUser.source == "BOX"
              ? "mdi-card-account-details-outline"
              : self.currentSwipedUser.source == "BEACON"
              ? "mdi-bluetooth-audio"
              : self.currentSwipedUser.source == "FACE"
              ? "mdi-face-recognition"
              : self.currentSwipedUser.source == "DESKTOP"
              ? "mdi-desktop-classic"
               : self.currentSwipedUser.source == "RFID"
              ? "mdi-card-account-details-outline"
               : self.currentSwipedUser.source == "SIMPLE"
              ? "mdi-line-scan"
              : "",
          text:
            self.currentSwipedUser.source != "PROXY"
              ? `${self.currentSwipedUser.user_name} swiped at ${
                  self.currentSwipedUser.box_location
                } - ${new Date(self.currentSwipedUser.swipe_time_stamp * 1000)
                  .toLocaleString()
                  .toUpperCase()}`
              : `${self.currentSwipedUser.user_name} swiped by ${
                  self.currentSwipedUser.proxy_by
                } - ${new Date(self.currentSwipedUser.swipe_time_stamp * 1000)
                  .toLocaleString()
                  .toUpperCase()}`,
          color:
            self.currentSwipedUser.source == "WHATSAPP"
              ? "#757575"
              : self.currentSwipedUser.source == "CAMERA"
              ? "#757575"
              : self.currentSwipedUser.source == "RESWIPE"
              ? "#757575"
              : self.currentSwipedUser.source == "DATACORRECTION"
              ? "#757575"
              : self.currentSwipedUser.source == "PROXY"
              ? "#757575"
              : self.currentSwipedUser.source == "BOX"
              ? "#757575"
              : self.currentSwipedUser.source == "BEACON"
              ? "#757575"
              : self.currentSwipedUser.source == "FACE"
              ? "#757575"
              : "",
          TotalCredits: self.currentSwipedUser.totalcredits,
          Consumedcredits: self.currentSwipedUser.consumedcredits,
        });
        // var audio = new Audio(require("@/assets/RingTone.ogg"));
        // audio.play();
      };
    },
  },
};
</script>

<style></style>
