<template>
  <div>
    <OverlayNetSign :overlaynet="overlaynet" />
    <v-app
      :style="{
        'background-image': 'url(' + require('@/assets/bg.jpg') + ')',
      }"
      style="background-size: cover"
    >
      <v-container class="fill-height" fluid>
        <v-card class="mx-auto" width="1000">
          <v-card-text>
            <v-row class="pt-3">
              <v-col cols="12" md="6" lg="6" xs="12" xl="6" sm="12">
                <v-img
                  max-width="100%"
                  max-height="1800px"
                  class="mt-n3"
                  src="@/assets/Prezencenew2.png"
                  @click="showLog"
                >
                </v-img>
              </v-col>
              <v-col cols="12" md="6" lg="6" xs="12" xl="6" sm="12">
                <!-- <v-row>
              <v-col cols="12">
                <div class="d-flex justify-center">
                  <v-img
                    max-width="80"
                    max-height="100"
                    src="@/assets/PrezencePrint.png"
                  >
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="green"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </div>
              </v-col>
            </v-row> -->
                <v-form ref="form">
                  <v-text-field
                    v-model="memberName"
                    outlined
                    :rules="rulesMembers"
                    dense
                    class="ml-6 mr-6"
                    label="Member Name*"
                  />
                  <v-text-field
                    v-model="OrganistionName"
                    outlined
                    :rules="org_name_rules"
                    :disabled="checkmember == false"
                    dense
                    class="ml-6 mr-6"
                    label="Organization Name*"
                    @change="check_orgnisationName()"
                    @keydown.enter="check_orgnisationName()"
                    @blur="check_orgnisationName()"
                  />
                  <v-card-actions class="mt-n6"
                    ><v-spacer />
                    <v-btn
                      text
                      class="green--text text-capitalize"
                      v-if="checkorg == true"
                    >
                      <v-icon class="mr-1" color="success"
                        >mdi-check-circle</v-icon
                      >
                      verified</v-btn
                    >
                  </v-card-actions>
                  <v-btn
                    small
                    class="white--text ml-6"
                    color="gradient-bg"
                    :disabled="checkorg == false || checkmember == false"
                    @click="openmap()"
                    >Select Location</v-btn
                  >
                  <!-- <gmap-autocomplete
                placeholder="Search Location"
                :value="search"
                ref="clearSearch"
                class="ml-8 mb-4"
                style="
                  border-bottom: 1px solid grey;
                  font-size: 18px;
                  width: 250px;
                "
                :disabled="checkorg == false"
                @place_changed="setPlace"
              ></gmap-autocomplete>
              <v-btn
                rounded
                small
                class="white--text ml-6"
                color="gradient-bg"
                @click="addMarker"
                :disabled="checkorg == false || checkmember == false"
                >Go</v-btn
              >
              <v-icon
                color="primary"
                @click="openmap()"
                class="ml-2"
                :disabled="checkorg == false || checkmember == false"
                >mdi-map-marker</v-icon
              > -->

                  <v-textarea
                    v-model="Organistionaddress"
                    outlined
                    dense
                    :disabled="
                      checkorg == false ||
                      checkmember == false ||
                      this.geocheck == false
                    "
                    :rules="address_rules"
                    height="80px"
                    class="ml-6 mr-6 mt-4"
                    label="Organization Address*"
                  />
                  <!-- :rules="address_rules" -->

                  <v-autocomplete
                    dense
                    outlined
                    :search-input.sync="searchL"
                    v-model="countryNamelist"
                    hide-no-data
                    :disabled="
                      checkorg == false ||
                      checkmember == false ||
                      this.geocheck == false
                    "
                    hide-selected
                    :items="countryitems"
                    class="ml-6 mr-6 mt-n1"
                    label="Country*"
                  />
                  <v-row>
                    <v-col cols="4">
                      <v-select
                        label="Country Code *"
                        v-model="CountryCode"
                        :items="CountryList"
                        item-value="phonecode"
                        :disabled="
                          checkorg == false ||
                          geocheck == false ||
                          checkmember == false ||
                          checkaddress == false ||
                          checkno == true
                        "
                        item-text="name"
                        class="ml-6"
                        dense
                      >
                        <template slot="selection" slot-scope="{ item }">
                          {{ item.flag }} ({{
                            item.phonecode.toString().includes("+") == false
                              ? "+" + item.phonecode
                              : item.phonecode
                          }})
                        </template>
                        <template slot="item" slot-scope="{ item }">
                          {{ item.flag }} {{ item.name }} ({{ item.phonecode }})
                        </template>
                      </v-select>
                    </v-col>
                    <v-col cols="8" v-if="checkno == false">
                      <v-text-field
                        dense
                        outlined
                        v-model="PhoneNumber"
                        :disabled="
                          checkorg == false ||
                          geocheck == false ||
                          checkmember == false ||
                          checkaddress == false
                        "
                        :rules="numberRules"
                        class="mr-6"
                        label="Phone Number*"
                      />
                    </v-col>
                    <v-col cols="8" v-if="checkno == true">
                      <v-text-field
                        dense
                        outlined
                        v-model="PhoneNumber"
                        :disabled="checkno == true"
                        :rules="numberRules"
                        class="mr-6"
                        label="Phone Number*"
                      />
                    </v-col>
                  </v-row>
                  <v-card-actions class="mt-n9"
                    ><v-spacer /><v-btn
                      v-show="isnumberValid"
                      v-if="checkno == false"
                      :disabled="
                        checkorg == false ||
                        geocheck == false ||
                        checkmember == false ||
                        checkaddress == false
                      "
                      x-small
                      :loading="phloading"
                      @click="check_phonenumber"
                      id="sign-in-button"
                      class="gradient-bg white--text text-capitalize mr-3"
                      >verify
                    </v-btn>
                    <v-btn
                      text
                      class="green--text text-capitalize"
                      v-if="checkno == true"
                    >
                      <v-icon class="mr-1" color="success"
                        >mdi-check-circle</v-icon
                      >
                      verified</v-btn
                    >
                  </v-card-actions>
                  <!-- open_phonenumberdailog -->
                  <v-text-field
                    dense
                    outlined
                    v-model="email"
                    :disabled="
                      checkorg == false ||
                      geocheck == false ||
                      checkno == false ||
                      checkmember == false ||
                      checkaddress == false
                    "
                    :rules="email_rules"
                    append-icon="mdi-email"
                    class="ml-6 mr-6"
                    label="Email*"
                  />
                  <v-card-actions class="mt-n6"
                    ><v-spacer /><v-btn
                      v-show="isEmailValid"
                      :disabled="
                        checkorg == false ||
                        geocheck == false ||
                        checkno == false ||
                        checkmember == false ||
                        checkaddress == false
                      "
                      x-small
                      class="gradient-bg white--text mr-3 text-capitalize"
                      @click="check_email"
                      >verify
                    </v-btn></v-card-actions
                  >
                </v-form>
                <v-card-actions class="justify-center">
                  <v-btn
                    class="gradient-bg white--text mt-n2"
                    width="180px"
                    disabled
                    small
                    >Sign Up</v-btn
                  >
                </v-card-actions>
                <v-row no-gutters class="justify-center">
                  <div>
                    Already a user ?
                    <v-btn x-small text color="#ef3f6e" @click="back_tologin()"
                      ><b><u>Login</u></b></v-btn
                    >
                  </div>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-dialog v-model="geoproof" persistent width="500px">
          <v-toolbar dense class="elevation-0 gradient-bg white--text">
            <v-toolbar-title>Location </v-toolbar-title>
            <v-spacer />
            <v-icon @click="close_dialog()" class="white--text mr-n3"
              >mdi-close</v-icon
            >
          </v-toolbar>
          <v-card>
            <v-card-text>
              <gmap-autocomplete
                placeholder="Search Location"
                :value="search"
                ref="clearSearch"
                class="ml-1 mb-6 mt-5"
                style="
                  border-bottom: 1px solid grey;
                  font-size: 18px;
                  width: 290px;
                "
                @place_changed="setPlace"
              ></gmap-autocomplete>
              <v-btn
                rounded
                small
                class="white--text ml-6"
                color="gradient-bg"
                @click="addMarker"
                >Go</v-btn
              >
              <v-btn
                class="white--text ml-2"
                color="gradient-bg"
                small
                :disabled="geocheck == false"
                @click="close_dialog()"
              >
                Save
              </v-btn>
              <GmapMap
                :center="
                  markers[0] != undefined ? markers[0].position : defaultCenter
                "
                :zoom="20"
                map-type-id="terrain"
                style="width: ; height: 300px"
              >
                <GmapMarker
                  :key="index"
                  v-for="(m, index) in markers"
                  :position="m.position"
                  :clickable="true"
                  :draggable="true"
                  @dragend="callposition"
                  @click="center = m.position"
                />
              </GmapMap>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-container>
    </v-app>
    <div v-if="componentcheck == 1">
      <PhoneNumberverification
        :otpverificarionNumber="otpverificarionNumber"
        :phoneitems="phoneitems"
        @clicked="close_prop"
        v-on:errorMsg="errorPop"
        v-on:successMsg="sucessPop"
        :sessiondata="sessiondata"
      />
    </div>
    <div v-if="componentcheck == 2">
      <EmailVerification
        :emailotp="emailotp"
        @clicked="close_prop"
        :emailitems="emailitems"
        :userDetails="userDetails"
        v-on:errorMsg="errorPop"
        v-on:successMsg="sucessPop"
      />
    </div>
    <Snackbar :SnackBarComponent="SnackBarComponent" />
  </div>
</template>

<script>
/* eslint-disable */
import { initializeApp } from "firebase/app";
import {
  getAuth,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from "firebase/auth";
import Snackbar from "@/components/SnackBar.vue";
import EmailVerification from "../../components/Dialogs/EmailVerification.vue";
import PhoneNumberverification from "../../components/Dialogs/PhoneNumberverification.vue";
import { Country } from "country-state-city";
import OverlayNetSign from "@/components/OverlayNetSign.vue";
import axios from "axios";
import * as usertz from "user-timezone";
import { Auth } from "aws-amplify";
export default {
  components: {
    Snackbar,
    // Overlay,
    EmailVerification,
    PhoneNumberverification,
    OverlayNetSign,
  },
  data() {
    return {
      email: "",
      memberName: "",
      search: "",
      emailotp: false,
      componentcheck: 0,
      SnackBarComponent: {},
      emailitems: {},
      phoneCode: "",
      otpverificarionNumber: false,
      checkno: false,
      emailCode: "",
      PhoneNumber: "",
      OrganistionName: "",
      Organistionaddress: "",
      latt: "",
      checkorg: false,
      phloading: false,
      checkaddress: false,
      longg: "",
      CountryList: [],
      phoneitems: {},
      countryitems: [],
      orgloading: false,
      geocheck: false,
      CountryCode: "",
      countryNamelist: "",
      new_timezone: "",
      geoproof: false,
      countryName: "",
      searchL: "",
      currentPlace: "",
      sessiondata: "",
      overlaynet: false,
      checkmember: false,
      userDetails: {},
      defaultCenter: {
        lat: 0,
        lng: 0,
      },
      markers: [],
      numberRules: [
        (x) => x.trim() != "" || "Contact Number cannot be empty",
        (v) => /^[0-9]{8,15}$/.test(v) || "Contact Number must be valid",
      ],
      email_rules: [(v) => /.+@.+\..+/.test(v) || "E-mail must be valid"],
      rulesMembers: [
        (v) => v.trim().length != 0 || "Member Name cannot be empty",
        (v) =>
          /^[a-z\sA-Z]{3,30}$/.test(v) ||
          "3 - 30 characters, no special characters, no numbers ",
      ],
      org_name_rules: [
        (v) => v.trim().length != 0 || "Organization Name cannot be empty",
        (v) =>
          /^[a-zA-Z0-9\s]{3,80}$/.test(v) ||
          "3 - 80 characters, no special characters",

        (v) =>
          v.length >= 3 ||
          "Organization Name must contain more than 3 characters",
        (v) =>
          v.length <= 80 ||
          "Organization Name cannot exceed more than 50 characters",
      ],
      otp_rules: [
        (v) => v.length != 0 || "OTP cannot be empty",
        (v) => v.length == 6 || "Enter Correct OTP",
      ],
      address_rules: [
        (v) => v.trim().length != 0 || "Organization Address cannot be empty",
        // (v) =>
        //   v.length >= 3 ||
        //   "Organization Address must contain more than 3 characters",
        (v) =>
          v.length <= 300 ||
          "Organization Address cannot exceed more than 300 characters",
      ],
    };
  },
  computed: {
    isEmailValid() {
      // You can implement your own logic to check the email validity based on your email_rules.
      // For example, you can use a regular expression to validate the email format.
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailPattern.test(this.email);
    },
    isnumberValid() {
      // You can implement your own logic to check the email validity based on your email_rules.
      // For example, you can use a regular expression to validate the email format.
      const numberPattern = /^[0-9]{8,15}$/;
      return numberPattern.test(this.PhoneNumber);
    },
  },
  watch: {
    OrganistionName(val) {
      if (this.checkorg == true) {
        if (val) {
          this.checkorg = false;
        }
      }
    },
    memberName(val) {
      if (
        val.trim().length > 2 &&
        this.rulesMembers.every((rule) => rule(val))
      ) {
        this.checkmember = true; // Set check to true
      } else {
        this.checkmember = false; // Set check to false if not meeting criteria
      }
    },
    Organistionaddress(val) {
      if (
        val.trim().length > 3 &&
        this.address_rules.every((rule) => rule(val))
      ) {
        this.checkaddress = true; // Set check to true
      } else {
        this.checkaddress = false; // Set check to false if not meeting criteria
      }
    },
    // PhoneNumber(val) {
    //   if (this.checkno == true) {
    //     if (val) {
    //       this.checkno = false;
    //     }
    //   }
    // },
  },
  created() {
    this.CountryList = Country.getAllCountries();
    this.countryitemsnew = Country.getAllCountries();

    // console.log(this.countryitems, "s");
    fetch(
      "https://api.geoapify.com/v1/ipinfo?apiKey=7dc7ae8d1cc54ab2ae3753110b2a7726"
    )
      .then((resp) => resp.json())
      .then((userLocationData) => {
        this.CountryName = userLocationData.country.name;
        var self = this;
        // console.log(self.CountryList);
        self.CountryList.forEach(function (CountryIDX) {
          if (CountryIDX.name == userLocationData.country.name) {
            self.CountryCode = CountryIDX.phonecode;
          }
        });
        self.countryitemsnew.forEach((element) => {
          this.countryitems.push(element.name);
        });

        self.countryitemsnew.forEach(function (CountryIDX) {
          if (CountryIDX.name == userLocationData.country.name) {
            self.countryNamelist = CountryIDX.name;
          }
        });
        // console.log(self.countryNamelist);
      });
    this.checkInternet();
    this.geoFindMe();
  },
  methods: {
    showLog() {
      window.location.href = "https://www.prznce.com/";
    },
    close_prop(val) {
      if (val == 0) {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "green",
          Top: true,
          timeout: 5000,
          SnackbarText: "Login Successfully!!",
        };
        this.emailotp = false;
        this.$router.push("/Dashboard");
      } else if (val == 1) {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          SnackbarText: "Invalid OTP",
          timeout: 5000,
          Top: true,
        };
      } else if (val == 2) {
        this.otpverificarionNumber = false;
      } else if (val == 3) {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "green",
          SnackbarText: "OTP Sent Successfully",
          timeout: 5000,
          Top: true,
        };
      } else if (val == 4) {
        this.otpverificarionNumber = false;
        this.checkno = true;
      }
    },
    geoFindMe() {
      const timezone = usertz.getTimeZone();
      // console.log(timezone,'timezone');
      this.new_timezone = timezone.includes("/")
        ? timezone.split("/")[1]
        : timezone;
      this.CountryList = Country.getAllCountries();
      fetch(
        "https://api.geoapify.com/v1/ipinfo?apiKey=7dc7ae8d1cc54ab2ae3753110b2a7726"
      )
        .then((resp) => resp.json())
        .then((userLocationData) => {
          this.CountryName = userLocationData.country.name;
        });
      // console.log(  this.CountryName);
      // console.log(this.new_timezone);
    },
    errorPop(val) {
      this.SnackBarComponent = {
        SnackbarVmodel: true,
        SnackbarColor: "red",
        Top: true,
        SnackbarText: val,
      };
    },
    sucessPop(val) {
      this.SnackBarComponent = {
        SnackbarVmodel: true,
        SnackbarColor: "green",
        Top: true,
        SnackbarText: val,
      };
      this.otpverificarionNumber = false;
      this.checkno = true;
    },
    async check_orgnisationName() {
      this.orgloading = true;
      await axios({
        method: "POST",
        url: "https://liql60b1h9.execute-api.ap-south-1.amazonaws.com/prezence/email_phone_avaliabilty_check_v2 ",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          command: "emailPhoneAvaliabiltyCheckV2",
          action_type: "ORGANISATION_NAME", //EMAIL //PHONE
          organisation_name: this.OrganistionName.trim(),
        },
      })
        .then((res) => {
          if (res.data.Status == "SUCCESS") {
            this.checkorg = true;
            this.orgloading = false;
          } else {
            this.orgloading = false;

            this.SnackBarComponent = {
              SnackbarVmodel: true,
              SnackbarColor: "red",
              Top: true,
              SnackbarText: "Organization Name already Exist",
            };
          }
        })
        .catch((err) => {
          console.log(err);
          this.orgloading = false;
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            Top: true,
            SnackbarText: err.errors[0].message,
          };
        });
    },
    async check_phonenumber() {
      var item = {
        PhoneNumber: this.PhoneNumber,
        CountryCode: this.CountryCode,
        username: this.memberName,
      };
      this.phoneitems = item;
      this.phloading = true;
      axios({
        method: "POST",
        url: "https://liql60b1h9.execute-api.ap-south-1.amazonaws.com/prezence/email_phone_avaliabilty_check_v2 ",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          command: "emailPhoneAvaliabiltyCheckV2",
          action_type: "WEB_PHONE", //EMAIL //PHONE "PHONE",
          user_full_contact_number: "+" + this.CountryCode + this.PhoneNumber,
        },
      })
        .then((res) => {
          // console.log(res);
          if (res.data.Status == "SUCCESS") {
            // this.callSubmit();
            this.phoneotp();
            // this.componentcheck = 1;
            // this.otpverificarionNumber = true;
            this.phloading = false;
          } else {
            this.phloading = false;

            this.SnackBarComponent = {
              SnackbarVmodel: true,
              SnackbarColor: "red",
              Top: true,
              SnackbarText: "Phone Number already Exist",
            };
          }
        })
        .catch((err) => {
          console.log(err);
          this.phloading = false;
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            Top: true,
            SnackbarText: err.errors[0].message,
          };
        });
    },
    async callSubmit() {
      this.isLoading = true;
      let firebaseConfig = {
        apiKey: "AIzaSyCF6eLUPT_dElBLgiYIf6WcP_kggfDMNQ0",
        authDomain: "prezence-f23d6.firebaseapp.com",
        projectId: "prezence-f23d6",
        storageBucket: "prezence-f23d6.appspot.com",
        messagingSenderId: "952543398854",
        appId: "1:952543398854:web:950f282f98afc0760500c7",
        measurementId: "G-6WKT1RGKLJ",
      };
      const app = initializeApp(firebaseConfig);
      const auth = getAuth(app);
      auth.languageCode = "en";
      // window.recaptchaVerifier = new RecaptchaVerifier(
      //   "sign-in-button",
      //   {
      //     size: "invisible",
      //   },
      //   auth
      // );
      const phoneNumber =
        (this.phoneitems.CountryCode.toString().includes("+") == false
          ? "+" + this.phoneitems.CountryCode
          : this.phoneitems.CountryCode) + this.phoneitems.PhoneNumber;
      if (!this.recaptchaVerifier) {
        this.recaptchaVerifier = new RecaptchaVerifier(
          "sign-in-button",
          {
            size: "invisible",
            callback: (e) => {
              console.log(e);
            },
          },
          auth
        );
      }
      if (this.recaptchaWidgetId !== "") {
        this.recaptchaVerifier.render().then((widgetId) => {
          if (
            typeof grecaptcha !== "undefined" &&
            typeof grecaptcha.reset === "function"
          ) {
            grecaptcha.reset(widgetId);
          }
          this.recaptchaWidgetId = widgetId;
        });
      } else {
        this.recaptchaVerifier.render().then((widgetId) => {
          this.recaptchaWidgetId = widgetId;
        });
      }
      this.phloading = false;
      // const appVerifier = new RecaptchaVerifier(
      //   "sign-in-button",
      //   {
      //     size: "invisible",
      //   },
      //   auth
      // );
      // console.log("1", auth, phoneNumber, this.recaptchaVerifier);
      // const appVerifier = window.recaptchaVerifier;
      // console.log(`phoneNumber=${phoneNumber} appVerifier=${appVerifier}`);
      // console.log(auth);
      await signInWithPhoneNumber(auth, phoneNumber, this.recaptchaVerifier)
        .then((confirmationResult) => {
          window.confirmationResult = confirmationResult;
          this.$store.commit("snackbar/showMessage", {
            content: "OTP Sent Successfully",
            color: "green",
          });
          this.componentcheck = 1;
          this.otpverificarionNumber = true;
        })
        .catch((error) => {
          console.log("errr", error);
          this.$store.commit("snackbar/showMessage", {
            content: error,
            color: "red",
          });
          // if (error) {
          // window.recaptchaVerifier.render().then((widgetId) => {
          // grecaptcha.reset(widgetId);
          // });
          //) this.callSubmit();
          // });
          this.isLoading = false;
          this.phloading = false;

          // if (error) {

          // }
        });
    },
    callposition(latLng) {
      this.markers = [];
      var marker = {
        lat: latLng.latLng.lat(),
        lng: latLng.latLng.lng(),
      };
      this.markers.push({ position: marker });
      (this.latt = marker.lat), (this.longg = marker.lng);
    },
    openmap() {
      this.geoproof = true;
    },
    close_dialog() {
      this.geoproof = false;
    },
    setPlace(place) {
      this.currentPlace = place;
    },
    addMarker() {
      this.Organistionaddress = this.currentPlace.formatted_address;
      this.markers = [];
      var marker = {
        lat: this.currentPlace.geometry.location.lat(),
        lng: this.currentPlace.geometry.location.lng(),
      };
      this.markers.push({ position: marker });
      (this.latt = marker.lat), (this.longg = marker.lng);
      this.geocheck = true;
      const geocoder = new google.maps.Geocoder();
      const latlng = this.markers[0].position;
      geocoder.geocode({ location: latlng }, (results, status) => {
        if (status === "OK") {
          if (results[0]) {
            const city = results[0].address_components.find((component) =>
              component.types.includes("locality")
            );
            console.log("City:", city ? city.long_name : "Not found");
          } else {
            console.log("No results found");
          }
        } else {
          console.log("Geocoder failed due to:", status);
        }
      });
    },
    async phoneotp() {
      this.isLoading = true;
      const phoneNumber =
        (this.phoneitems.CountryCode.toString().includes("+") == false
          ? "+" + this.phoneitems.CountryCode
          : this.phoneitems.CountryCode) + this.phoneitems.PhoneNumber;
      axios({
        method: "POST",
        url: "https://liql60b1h9.execute-api.ap-south-1.amazonaws.com/prezence/verifyGenerateOtp",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          command: "otpGenerateForUser",
          action_type: "OTP_GENERATOR",
          contact_number: phoneNumber,
        },
      })
        .then((res) => {
          if (res.data.Status == "SUCCESS") {
            this.sessiondata = "";

            this.sessiondata = res.data.session;
            this.$store.commit("snackbar/showMessage", {
              content: "OTP Sent Successfully",
              color: "green",
            });
            this.componentcheck = 1;
            this.otpverificarionNumber = true;
          } else {
            this.sessiondata = "";
            this.orgloading = false;
          }
        })
        .catch((error) => {
          if (error.response) {
            // Server responded with a status other than 2xx
            console.error("Response error:", error.response.data);
          } else if (error.request) {
            // Request was made but no response received
            console.error("Request error:", error.request);
          } else {
            // Something happened in setting up the request
            console.error("Error:", error.message);
          }
        });
    },
    async check_email() {
      this.orgloading = true;
      axios({
        method: "POST",
        url: "https://liql60b1h9.execute-api.ap-south-1.amazonaws.com/prezence/email_phone_avaliabilty_check_v2 ",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          command: "emailPhoneAvaliabiltyCheckV2",
          action_type: "EMAIL",
          user_email_id: this.email,
        },
      })
        .then((res) => {
          if (res.data.Status == "SUCCESS") {
            this.generate_otp();
            // this.componentcheck = 2;
            // this.emailotp = true;
            this.orgloading = false;
          } else {
            this.orgloading = false;

            this.SnackBarComponent = {
              SnackbarVmodel: true,
              SnackbarColor: "red",
              Top: true,
              SnackbarText: "Email already Exist",
            };
          }
        })
        .catch((err) => {
          console.log(err);
          this.orgloading = false;
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            Top: true,
            SnackbarText: err.errors[0].message,
          };
        });
    },
    async generate_otp() {
      var items = {
        organisation_country: this.countryNamelist,
        email: this.email,
        organisation_city: this.new_timezone,
        organisation_name: this.OrganistionName,
        organisation_address: this.Organistionaddress,
        user_contact_number: this.PhoneNumber,
        user_country_code: "+" + this.CountryCode,
        user_name: this.memberName,
        geo_lat: this.latt,
        geo_long: this.longg,
      };
      this.emailitems = items;
      try {
        const user = await Auth.signIn(this.email)
          .then((user) => {
            this.userDetails = user;

            this.SnackBarComponent = {
              SnackbarVmodel: true,
              SnackbarColor: "green",
              SnackbarText: "OTP Sent Successfully",
              timeout: 5000,
              Top: true,
            };
            this.componentcheck = 2;
            this.emailotp = true;
            this.focusOnOTPField();
          })
          .catch((err) => {
            this.SnackBarComponent = {
              SnackbarVmodel: true,
              SnackbarColor: "red",
              SnackbarText: "Couldn't find your Prezence account!!",
              timeout: 5000,
              Top: true,
            };
          });
      } catch (error) {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          SnackbarText: error.message,
          timeout: 5000,
          Top: true,
        };
      }
    },
    focusOnOTPField() {
      this.$nextTick(() => {
        setTimeout(() => {
          this.$refs[this.otpRef].focus();
        }, 100); // Adjust the delay as needed (100ms in this case)
      });
    },
    open_phonenumberdailog() {
      this.componentcheck = 1;
      this.otpverificarionNumber = true;
    },

    checkInternet() {
      this.network = setInterval(() => {
        this.checknetwork();
      }, 5000);
    },
    checknetwork() {
      var ifConnected = window.navigator.onLine;
      if (!ifConnected) {
        this.overlay = false;
        this.overlaynet = true;
      } else {
        this.overlaynet = false;
      }
    },
    back_tologin() {
      this.$router.push("/");

      // this.$router.push({
      //   name: "NewLogin",
      //   params: {
      //     otp: 1,
      //     email: this.email,
      //   },
      // });
    },
  },
};
</script>

<style>
</style>