import Vuex from "vuex";
import Vue from "vue";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

const snackbar = {
    namespaced: true,
    state: () => ({

        content: "",
        color: "",
    }),
    mutations: {
        showMessage(state, payload) {
            state.content = payload.content;
            state.color = payload.color;
        },
    },
};

export default new Vuex.Store({
    state: {
        getPreviousRoute: "",
        auth: false,
        email: '',
        OrgDetails: {},
        UserDetails: {},
        routeObject: {},
        access: [],
        contact_number: '',
        country_code: '',
        name: '',
        getIssueDetails: '',
    },
    modules: {
        snackbar
    },
    plugins: [createPersistedState()],

    mutations: {
        set_org_contact_number(state, contact_number) {
            state.contact_number = contact_number;
        },
        set_org_country_code(state, country_code) {
            state.country_code = country_code;
        },
        SET_AUTH(state, auth) {
            state.auth = auth;
        },
        SET_USEREMAIL(state, email) {
            state.email = email;
        },
        set_org_details(state, OrgDetails) {
            state.OrgDetails = OrgDetails;
        },
        set_org_user_details(state, UserDetails) {
            state.UserDetails = UserDetails;
        },
        set_access_details(state, access) {
            state.access = access;
        },
        SET_CURRENT_ROUTE(state, routeObject) {
            state.routeObject = routeObject;
        },
        set_org_user_name(state, name) {
            state.name = name;
        },
        SET_ISSUE_DETAILS(state, getIssueDetails) {
            state.getIssueDetails = getIssueDetails;
        },
        SET_PREVIOUS_ROUTE(state, route) {
            state.getPreviousRoute = route;
        },
    },
    getters: {
        get_access_details: (state) => {
            return state.access;
        },
        get_org_user_name: (state) => {
            return state.name;
        },
        get_org_contact_number: (state) => {
            return state.contact_number;
        },
        get_org_country_code: (state) => {
            return state.country_code;
        },
        get_auth: (state) => {
            return state.auth || false;
        },
        get_useremail: state => {
            return state.email;
        },
        get_org_details: (state) => {
            return state.OrgDetails;
        },
        get_org_user_details: (state) => {
            return state.UserDetails;
        },
        get_current_route: (state) => {
            return state.routeObject;
        }, get_issue_details: (state) => {
            return state.getIssueDetails;
        },
        get_previous_route: (state) => {
            return state.getPreviousRoute;
        },
    },

    actions: {

    },
});