<template>
  <div id="cap">
    <v-app-bar color="theme" app clipped-left dense>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <div>
        <div v-if="this.$vuetify.breakpoint.xs" class="ml-n2"></div>
        <div v-else>
          <v-img
            contain
            max-width="100px"
            v-if="$store.getters.get_org_details[0].organisation_logo != null"
            :src="$store.getters.get_org_details[0].organisation_logo"
            height="50px"
          >
          </v-img>
        </div>
      </div>
      <div v-if="this.$vuetify.breakpoint.xs" class=""></div>
      <v-divider
        vertical
        style="margin-left: 72px"
        v-if="$store.getters.get_org_details[0].organisation_logo != null"
      ></v-divider>

      <img class="ml-3" src="@/assets/PrezenceNew.png" width="130px" />
      <v-spacer />
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            icon
            @click="face_route()"
            color="primary"
            class=""
          >
            <v-icon>mdi-face-recognition</v-icon>
          </v-btn>
        </template>
        <span>Face</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            icon
            @click="report_issue()"
            color="black"
            class="mr-5"
          >
            <v-icon>mdi-information</v-icon>
          </v-btn>
        </template>
        <span>Facing Issues Using Prezence ? Click To Report</span>
      </v-tooltip>
      <div v-if="this.$vuetify.breakpoint.xs"></div>
      <div v-else>
        <v-menu
          v-model="menu"
          bottom
          left
          :close-on-content-click="false"
          :nudge-width="200"
          offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-badge
              v-show="Count > 0"
              color="green"
              :content="Count"
              class=""
            ></v-badge>
            <v-icon
              class="ml-n6 mt-3"
              color="primary"
              v-bind="attrs"
              v-on="on"
              @click="Count = 0"
            >
              mdi-bell-ring-outline
            </v-icon>
          </template>
          <v-card class="elevation-0">
            <v-list dense v-if="notificationArray.length != 0">
              <v-list-item-group>
                <v-list-item v-for="(item, i) in notificationArray" :key="i">
                  <v-list-item-icon>
                    <v-icon small :color="item.color">{{ item.icon }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ item.text }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
            <v-card
              flat
              v-if="notificationArray.length == 0"
              class="text-center"
            >
              <v-card-text class="elevation-0 text-center ma-1">
                <div class="font-weight-bold">No new notifications</div>
              </v-card-text>
            </v-card>
          </v-card>
        </v-menu>

        <div align="center" class="mx-5 pa-0 mt-n8">
          {{ $store.getters.get_useremail.toLowerCase() }}
          <div style="font-size: 14px" class="mt-n1" align="center">
            ( {{ $store.getters.get_org_details[0].organisation_name }} )
          </div>
        </div>
      </div>
      <v-icon color="red" @click="logout()">mdi-power</v-icon>
    </v-app-bar>
    <v-navigation-drawer
      width="225px"
      color="theme"
      clipped
      v-model="drawer"
      class=""
      app
    >
      <v-card
        width="100%"
        v-ripple="false"
        v-for="(route, idx) in items"
        :key="idx"
        :color="route.color"
        @click="changeRoute(route, idx, 'MAINROUTE')"
        class="elevation-0 borderSizeZero"
        :class="route.color == 'primary' ? 'white--text' : ''"
        dense
      >
        <div class="mt-1 mb-1">
          <v-list class="pa-0" dense>
            <div v-show="route.has_child_routes == false">
              <v-list-item
                @click="
                  (selectedItemGroup = false),
                    (selectedItemGroup1 = false),
                    (selectedItemGroup2 = false),
                    (selectedItemGroup3 = false)((activeTab = null))
                "
              >
                <v-list-item-icon>
                  <v-icon
                    :class="route.color == 'primary' ? 'white--text' : ''"
                    >{{ route.icon }}</v-icon
                  >
                </v-list-item-icon>

                <v-list-item-title
                  class="ml-n6"
                  :class="route.color == 'primary' ? 'white--text' : ''"
                  >{{ route.title }}</v-list-item-title
                >
              </v-list-item>
            </div>
            <div v-if="route.has_child_routes2 == true" class="">
              <v-list-group
                :value="false"
                no-action
                v-model="selectedItemGroup2"
                @click="
                  (selectedItemGroup1 = false),
                    (selectedItemGroup = false),
                    (selectedItemGroup3 = false),
                    (activeTab = null)
                "
                color="white"
                class="mb-16"
              >
                <template v-slot:activator>
                  <v-list-item-icon>
                    <v-icon
                      :class="route.color == 'primary' ? 'white--text' : ''"
                      >{{ route.icon }}</v-icon
                    >
                  </v-list-item-icon>

                  <v-list-item-title
                    class="ml-n6"
                    :class="route.color == 'primary' ? 'white--text' : ''"
                    >{{ route.title }}
                  </v-list-item-title>
                </template>
                <v-card flat class="" dense>
                  <v-list-item-content class="mb-n4 pa-0">
                    <v-list-item
                      v-for="(subroute, sidx) in route.items"
                      :key="sidx"
                      v-show="current_idx == idx"
                      class="elevation-0 borderSizeZero"
                      :class="
                        $route.name == subroute.to
                          ? 'white--text light_primary'
                          : ''
                      "
                      @click="changeRoute(subroute, idx, 'SUBROUTE')"
                      ><span class="ml-8" style="font-size: smaller">{{
                        subroute.title
                      }}</span></v-list-item
                    >
                  </v-list-item-content>
                </v-card>
              </v-list-group>
            </div>
            <div v-if="route.has_child_routes1 == true" class="">
              <v-list-group
                :value="false"
                no-action
                v-model="selectedItemGroup3"
                @click="
                  (selectedItemGroup1 = false),
                    (selectedItemGroup = false),
                    (selectedItemGroup2 = false),
                    (activeTab = null)
                "
                color="white"
                class="mb-3"
              >
                <template v-slot:activator>
                  <v-list-item-icon>
                    <v-icon
                      :class="route.color == 'primary' ? 'white--text' : ''"
                      >{{ route.icon }}</v-icon
                    >
                  </v-list-item-icon>

                  <v-list-item-title
                    class="ml-n6"
                    :class="route.color == 'primary' ? 'white--text' : ''"
                    >{{ route.title }}
                  </v-list-item-title>
                </template>
                <v-card flat class="" dense>
                  <v-list-item-content class="mb-n4 pa-0">
                    <v-list-item
                      v-for="(subroute, sidx) in route.items"
                      :key="sidx"
                      v-show="current_idx == idx"
                      class="elevation-0 borderSizeZero"
                      :class="
                        $route.name == subroute.to
                          ? 'white--text light_primary'
                          : ''
                      "
                      @click="changeRoute(subroute, idx, 'SUBROUTE')"
                      ><span class="ml-8" style="font-size: smaller">{{
                        subroute.title
                      }}</span></v-list-item
                    >
                  </v-list-item-content>
                </v-card>
              </v-list-group>
            </div>
            <div v-if="route.has_sub == true" class="">
              <v-list-group
                :value="true"
                prepend-icon="mdi-file-document"
                color="white"
                v-model="selectedItemGroup"
                @click="
                  (selectedItemGroup1 = false),
                    (selectedItemGroup2 = false)((selectedItemGroup3 = false))
                "
              >
                <template v-slot:activator>
                  <v-list-item-title
                    class="ml-n5"
                    :class="route.color == 'primary' ? 'white--text' : ''"
                    >{{ route.title }}</v-list-item-title
                  >
                </template>
                <v-card flat class="" dense>
                  <v-list-group
                    :value="true"
                    no-action
                    sub-group
                    color="white"
                    v-model="minItemGroup1"
                    @click="
                      (activeTab = 'daily'),
                        (minItemGroup = false),
                        (minItemGroup2 = false)
                    "
                    :class="{ 'primary white--text': activeTab === 'daily' }"
                  >
                    <template v-slot:activator>
                      <v-list-item-content style="color: black">
                        <v-list-item-title
                          :class="{ ' white--text': activeTab === 'daily' }"
                          >Daily Reports</v-list-item-title
                        >
                      </v-list-item-content>
                    </template>
                    <v-card flat class="" dense>
                      <v-list-item
                        v-for="(subroute, sidx) in route.items3"
                        :key="sidx"
                        :class="
                          $route.name == subroute.to
                            ? 'white--text light_primary'
                            : ''
                        "
                        link
                        @click="changeRoute(subroute, idx, 'SUBROUTE')"
                      >
                        <v-list-item-title class="ml-6 font-weight-regular">{{
                          subroute.title
                        }}</v-list-item-title>
                        <!-- 
                    <v-list-item-icon>
                      <v-icon v-text="icon"></v-icon>
                    </v-list-item-icon> -->
                      </v-list-item>
                    </v-card>
                  </v-list-group>
                  <!-- <v-list-group
                    :value="true"
                    no-action
                    sub-group
                    color="white"
                    v-model="minItemGroup2"
                    @click="
                      (activeTab = 'weak'),
                        (minItemGroup = false)((minItemGroup1 = false))
                    "
                    :class="{ 'primary ': activeTab === 'weak' }"
                  >
                    <template v-slot:activator>
                      <v-list-item-content>
                        <v-list-item-title
                          style="color: black"
                          :class="{ 'white--text': activeTab === 'weak' }"
                          >Weekly Reports</v-list-item-title
                        >
                      </v-list-item-content>
                    </template>
                    <v-card flat class="" dense>
                      <v-list-item
                        v-for="(subroute, sidx) in route.items2"
                        :key="sidx"
                        :class="
                          $route.name == subroute.to
                            ? 'white--text light_primary'
                            : ''
                        "
                        link
                        @click="changeRoute(subroute, idx, 'SUBROUTE')"
                      >
                        <v-list-item-title class="ml-5 font-weight-regular">{{
                          subroute.title
                        }}</v-list-item-title>
                
                      </v-list-item>
                    </v-card>
                  </v-list-group> -->
                  <v-list-item :class="{ primary: activeTab === 'weakly' }">
                    <v-list-item-icon @click="changeRoute2()">
                      <!-- <v-icon :class="route.color == 'primary' ? 'white--text' : ''"
                    >mdi-chart-box-outline</v-icon
                  > -->

                      <v-list-item-title
                        @click="changeRoute2()"
                        style="font-size: small"
                        class="ml-12"
                        :class="{ 'white--text': activeTab === 'weakly' }"
                        ><b>Weekly Reports</b></v-list-item-title
                      >
                    </v-list-item-icon>
                  </v-list-item>
                  <v-list-group
                    :value="true"
                    no-action
                    sub-group
                    color="white"
                    v-model="minItemGroup"
                    @click="
                      (activeTab = 'monthly'),
                        (minItemGroup1 = false),
                        (minItemGroup2 = false)
                    "
                    :class="{ 'primary ': activeTab === 'monthly' }"
                  >
                    <template v-slot:activator>
                      <v-list-item-content>
                        <v-list-item-title
                          style="color: black"
                          :class="{ 'white--text': activeTab === 'monthly' }"
                          >Monthly Reports</v-list-item-title
                        >
                      </v-list-item-content>
                    </template>
                    <v-card flat class="" dense>
                      <v-list-item
                        v-for="(subroute, sidx) in route.items1"
                        :key="sidx"
                        :class="
                          $route.name == subroute.to
                            ? 'white--text light_primary'
                            : ''
                        "
                        link
                        @click="changeRoute(subroute, idx, 'SUBROUTE')"
                      >
                        <v-list-item-title class="ml-5 font-weight-regular">{{
                          subroute.title
                        }}</v-list-item-title>
                        <!-- 
                    <v-list-item-icon>
                      <v-icon v-text="icon"></v-icon>
                    </v-list-item-icon> -->
                      </v-list-item>
                    </v-card>
                  </v-list-group>

                  <v-list-item :class="{ primary: activeTab === 'export' }">
                    <v-list-item-icon @click="changeRoute1()">
                      <!-- <v-icon :class="route.color == 'primary' ? 'white--text' : ''"
                    >mdi-chart-box-outline</v-icon
                  > -->

                      <v-list-item-title
                        @click="changeRoute1()"
                        style="font-size: small"
                        class="ml-12"
                        :class="{ 'white--text': activeTab === 'export' }"
                        ><b>Export Reports</b></v-list-item-title
                      >
                    </v-list-item-icon>
                  </v-list-item>
                  <v-list-item :class="{ primary: activeTab === 'appusers' }">
                    <v-list-item-icon @click="changeRoute3()">
                      <!-- <v-icon :class="route.color == 'primary' ? 'white--text' : ''"
                    >mdi-chart-box-outline</v-icon
                  > -->

                      <v-list-item-title
                        @click="changeRoute3()"
                        style="font-size: small"
                        class="ml-12"
                        :class="{ 'white--text': activeTab === 'appusers' }"
                        ><b>Apps Reports</b></v-list-item-title
                      >
                    </v-list-item-icon>
                  </v-list-item>
                  <v-list-item :class="{ primary: activeTab === 'poofwrk' }">
                    <v-list-item-icon @click="changeRoute4()">
                      <!-- <v-icon :class="route.color == 'primary' ? 'white--text' : ''"
                    >mdi-chart-box-outline</v-icon
                  > -->

                      <v-list-item-title
                        @click="changeRoute4()"
                        style="font-size: small"
                        class="ml-12"
                        :class="{ 'white--text': activeTab === 'poofwrk' }"
                        ><b>Proof of work</b></v-list-item-title
                      >
                    </v-list-item-icon>
                  </v-list-item>
                  <v-list-item :class="{ primary: activeTab === 'datacrk' }">
                    <v-list-item-icon @click="changeRoute5()">
                      <!-- <v-icon :class="route.color == 'primary' ? 'white--text' : ''"
                    >mdi-chart-box-outline</v-icon
                  > -->

                      <v-list-item-title
                        @click="changeRoute5()"
                        style="font-size: small"
                        class="ml-12"
                        :class="{ 'white--text': activeTab === 'datacrk' }"
                        ><b>Data Correction </b></v-list-item-title
                      >
                    </v-list-item-icon>
                  </v-list-item>
                  <v-list-item :class="{ primary: activeTab === 'livetrack' }">
                    <v-list-item-icon @click="changeRoute6()">
                      <v-list-item-title
                        @click="changeRoute4()"
                        style="font-size: small"
                        class="ml-12"
                        :class="{ 'white--text': activeTab === 'livetrack' }"
                        ><b>Live Tracking</b></v-list-item-title
                      >
                    </v-list-item-icon>
                  </v-list-item>
                </v-card>
              </v-list-group>
            </div>
          </v-list>
        </div>
      </v-card>
    </v-navigation-drawer>
    <!-- <div ref="home">
      <v-main>
        <router-view />
      </v-main>
    </div> -->
    <OverlayNet :overlay="overlay" />
    <OverlayO :overlayO="overlayO" />
    <ReportDialog
      @close_report_dialog="Reportissue = false"
      :Reportissue="Reportissue"
      :items="items"
    ></ReportDialog>

    <LogOut :exitdialog="exitdialog" @clicked="exitdialog = false" />
  </div>
</template>
<script>
import LogOut from "@/components/Dialogs/LogOut.vue";
import { Auth } from "aws-amplify";
// import * as htmlToImage from "html-to-image";
import { GetCurrentUserDetailsnew } from "@/mixins/getCurrentUserDetails.js";
import OverlayNet from "@/components/OverlayNet.vue";
import OverlayO from "@/components/OverlayO.vue";
import ReportDialog from "@/components/Dialogs/ReportDialog.vue";

export default {
  components: {
    OverlayNet,
    OverlayO,
    ReportDialog,
    LogOut,
  },
  props: {
    source: String,
    currentSwipedUser: Object,
    notificationArray: Array,
  },
  mixins: [GetCurrentUserDetailsnew],
  data: () => ({
    overlayO: false,
    activeTab: null,
    selectedItemGroup: null,
    exitdialog: false,
    selectedItemGroup1: null,
    selectedItemGroup2: null,
    selectedItemGroup3: null,
    minItemGroup: null,
    minItemGroup1: null,
    minItemGroup2: null,
    Reportissue: false,
    StoreObj: {},
    selectedFilesFromDrop: [],
    base64URL: null,
    showSubRoutes: false,
    firstClick: true,
    selectedGroup: 0,
    Count: 0,
    menu: false,
    drawer: true,
    overlay: false,
    model: "",
    admins: [
      ["Management", "mdi-account-multiple-outline"],
      ["Settings", "mdi-cog-outline"],
    ],
    items: [
      {
        icon: "mdi-monitor-dashboard",
        title: "Dashboard",
        to: "Dashboard",
        has_child_routes: false,
        has_child_routes1: false,
        has_child_routes2: false,
        active: true,
        color: "transparent",
      },
      {
        icon: "mdi-account-multiple-plus",
        title: "Members",
        to: "Members",
        has_child_routes: false,
        has_child_routes1: false,
        has_child_routes2: false,
        active: false,
        color: "",
      },
      {
        icon: "mdi-office-building-marker-outline",
        title: "Locations",
        has_child_routes: false,
        has_child_routes1: false,
        has_child_routes2: false,
        to: "Locations",
        active: false,
        color: "",
      },
      // {
      //   icon: "mdi-account-circle",
      //   title: "Face Scan",
      //   has_child_routes: false,
      //   has_child_routes1: false,
      //   has_child_routes2: false,
      //   to: "FaceScan",
      //   active: false,
      //   color: "",
      // },
      {
        icon: "mdi-account-star",
        title: "Groups",
        has_child_routes: false,
        has_child_routes1: false,
        has_child_routes2: false,
        to: "GroupList",
        active: false,
        color: "",
      },

      {
        icon: "mdi-file",
        title: "Reports",
        color: "",
        has_sub: true,
        items1: [
          {
            title: "Scan Data",
            to: "SwipeDataReport",
          },
          {
            title: "Scan Count",
            to: "SwipeCountReport",
          },
          {
            title: "Normalized Report",
            to: "NormalizedReports",
          },
          {
            title: "Late In / Arrival",
            to: "LateArrivalReport",
          },
          {
            title: "Early Out",
            to: "EarlyGoers",
          },
          {
            title: "Short Duration",
            to: "ShortDurationReport",
          },
          {
            title: "Present Report",
            to: "PresentReport",
          },
          {
            title: "Worked On Holidays",
            to: "WorkingHoliday",
          },
        ],
        items3: [
          {
            title: "Present Report",
            to: "DailyPresentReport",
          },
          {
            title: "Absent Report",
            to: "DailyAbsentReport",
          },
          {
            title: "Late In / Arrival",
            to: "DailyLateReport",
          },
          {
            title: "Proof of Work Report",
            to: "DailyProofWork",
          },
          {
            title: "Proxy Report",
            to: "DailyProxy",
          },
          {
            title: "Leave Report",
            to: "LeaveReport",
          },
        ],
        items2: [
          {
            title: "Weak Count",
            to: "WeeklyData",
          },
        ],
        active: false,
      },
      {
        icon: "mdi-card-account-mail-outline",
        title: "Approvals",

        color: "",
        items: [
          {
            title: "Leave",
            to: "LeaveApprovellist",
          },
          {
            title: "ESA",
            to: "EsaTransaction",
          },
          {
            title: "Profile Picture",
            to: "ProfilApprove",
          },
        ],
        has_child_routes1: true,
        active: false,
      },
      {
        icon: "mdi-cards-outline",
        title: "Scan Log",
        to: "ScanLogs",
        has_child_routes: false,
        has_child_routes1: false,
        has_child_routes2: false,
        active: false,
        color: "",
      },

      // {
      //   icon: "mdi-account-settings-outline",
      //   title: "Leave Approvals",
      //   has_child_routes: false,
      //   has_child_routes1: false,
      //   has_child_routes2: false,
      //   to: "LeaveApprovellist",
      //   active: false,
      //   color: "",
      // },
      // {
      //   icon: "mdi-cash",
      //   title: "ESA Transaction",
      //   has_child_routes: false,
      //   has_child_routes1: false,
      //   has_child_routes2: false,
      //   to: "EsaTransaction",
      //   active: false,
      //   color: "",
      // },
      {
        icon: "mdi-file-edit",
        title: "Data Correction",
        has_child_routes: false,
        has_child_routes1: false,
        has_child_routes2: false,
        to: "DataCorrection",
        active: false,
        color: "",
      },
      {
        icon: "mdi-account-multiple",
        title: "Administrators",
        has_child_routes: false,
        has_child_routes1: false,
        has_child_routes2: false,
        to: "Administrators",
        active: false,
        color: "",
      },
      {
        icon: "mdi-recycle",
        title: "Recharge",
        has_child_routes: false,
        has_child_routes1: false,
        has_child_routes2: false,
        to: "Transactions",
        active: false,
        color: "",
      },
      {
        icon: "mdi-math-log",
        title: "Audit Logs",
        has_child_routes: false,
        has_child_routes1: false,
        has_child_routes2: false,
        to: "AuditLogs",
        active: false,
        color: "",
      },
      {
        icon: "mdi-cog",
        title: "Settings",
        color: "",
        items: [
          {
            title: "ESA",
            to: "EsA",
          },
          {
            title: "Work Timings",
            to: "WorkTimingsN",
          },
          {
            title: "Holiday List",
            to: "HolidayList",
          },
          {
            title: "Leave Settings",
            to: "LeaveSettings",
          },
          {
            title: "Designation",
            to: "DesginationList",
          },
          {
            title: "Employee Type",
            to: "EmployeetypeList",
          },
          {
            title: "Organization Details",
            to: "OrganisationDetails",
          },
          {
            title: "Scan Settings",
            to: "ScanSettings",
          },
          // {
          //   title: "Proof of Work Settings",
          //   to: "ProofworkSettings",
          // },
          {
            title: "Proof of Work Settings",
            to: "ProofOfWorkType",
          },
          {
            title: "Advanced Settings",
            to: "AdvanceSettings",
          },
        ],
        has_child_routes2: true,
        active: false,
      },
    ],
    current_idx: null,
    current_sub_idx: null,
    routeItem: {
      current_idx: null,
      current_sub_idx: null,
    },
    dialogCreateIssue: false,
  }),
  watch: {
    "$route.name"(val) {
      for (let i = 0; i < this.items.length; i++) {
        if (this.items[i].to == val) {
          this.current_idx = i;
          ``;
        }
      }
      this.items[this.current_idx].color = "primary";
      for (let i = 0; i < this.items.length; i++) {
        if (this.current_idx != i) {
          this.items[i].color = "transparent";
        }
      }
      // this.items[!this.current_idx].color = "transparent";
    },
    menu(val) {
      if (val == false) {
        this.$emit("navbaremit");
      }
    },
    currentSwipedUser() {
      this.Count += 1;
    },
  },
  created() {
    this.GetCurrentUserDetailsnew();
    this.checkInternet();
  },
  mounted() {
    this.updateNavigationValueMethod();
    console.warn(
      "Check Current IDX",
      this.$store.getters.get_current_route.current_idx
    );
    if (
      this.$store.getters.get_current_route.current_idx != null &&
      this.$store.getters.get_current_route.current_idx != undefined
    ) {
      this.items[this.$store.getters.get_current_route.current_idx].color =
        "primary";
      this.current_idx = this.$store.getters.get_current_route.current_idx;
      if (
        this.items[this.$store.getters.get_current_route.current_idx]
          .has_child_routes == true ||
        this.items[this.$store.getters.get_current_route.current_idx]
          .has_child_routes1 == true ||
        this.items[this.$store.getters.get_current_route.current_idx]
          .has_child_routes2 == true
      ) {
        this.items[this.$store.getters.get_current_route.current_idx].items[
          this.$store.getters.get_current_route.current_sub_idx
        ].color = "light_primary";
      }
    } else {
      this.$store.commit("SET_CURRENT_ROUTE", 0);
      this.$router.push("Dashboard");
      this.items[0].color = "primary";
      this.current_idx = 0;
    }
  },
  methods: {
    face_route() {
      this.$router.push("/FaceScan");
    },
    changeRoute1() {
      this.activeTab = "export";
      this.$router.push("/ExportReports");
      this.minItemGroup1 = false;
      this.minItemGroup = false;
      this.minItemGroup2 = false;
    },
    changeRoute2() {
      this.activeTab = "weakly";
      this.$router.push("/WeeklyData");
      this.minItemGroup1 = false;
      this.minItemGroup = false;
      this.minItemGroup2 = false;
    },
    changeRoute3() {
      this.activeTab = "appusers";
      this.$router.push("/AppUserReports");
      this.minItemGroup1 = false;
      this.minItemGroup = false;
      this.minItemGroup2 = false;
    },
    changeRoute4() {
      this.activeTab = "poofwrk";
      this.$router.push("/ProofwrkReport");
      this.minItemGroup1 = false;
      this.minItemGroup = false;
      this.minItemGroup2 = false;
    },
    changeRoute6() {
      this.activeTab = "livetrack";
      this.$router.push("/LiveTrackingReport");
      this.minItemGroup1 = false;
      this.minItemGroup = false;
      this.minItemGroup2 = false;
    },
    changeRoute5() {
      this.activeTab = "datacrk";
      this.$router.push("/DataCorrectionreport");
      this.minItemGroup1 = false;
      this.minItemGroup = false;
      this.minItemGroup2 = false;
    },
    report_issue() {
      this.Reportissue = true;
    },
    updateNavigationValueMethod() {
      let previous_route = this.$store.getters.get_previous_route;
      if (previous_route && previous_route != "") {
        switch (previous_route) {
          case "UserChannels":
            this.selectedItem = 0;
            break;
          case "SystemChannels":
            this.selectedItem = 1;
            break;
          case "RaydeoUsers":
            // console.log("check coming inside raydeo users");
            this.selectedItem = 2;
            break;
          case "BMSUsers":
            this.selectedItem = 3;
            break;
          case "Settings":
            this.selectedItem = 4;
            break;
        }
      }

      this.$forceUpdate();
    },

    dialogCreateIssueEmit() {
      this.dialogCreateIssue = false;
      this.selectedFilesFromDrop = [];
    },

    checkInternet() {
      this.network = setInterval(() => {
        this.checknetwork();
      }, 5000);
    },
    checknetwork() {
      var ifConnected = window.navigator.onLine;
      if (!ifConnected) {
        this.overlay = true;
      } else {
        this.overlay = false;
      }
    },
    changeRoute(route, idx, Action) {
      if (
        this.$store.getters.get_org_details[0].customer_activation == "ACTIVE"
      ) {
        if (
          route.has_child_routes == true ||
          route.has_child_routes1 == true ||
          route.has_child_routes2 == true
        ) {
          if (this.firstClick == true) {
            this.showSubRoutes = true;
            this.firstClick = false;
          } else if (this.current_idx == idx) {
            this.showSubRoutes = false;
            this.firstClick = true;
          }
        } else {
          this.showSubRoutes = false;
          this.firstClick = true;
        }
        if (Action == "MAINROUTE") {
          this.current_idx = null;
          this.routeItem.current_idx = idx;
          this.items[idx].color = "primary";
          for (let i = 0; i < this.items.length; i++) {
            if (i != idx) {
              this.items[i].color = "transparent";
            }
          }
          if (
            this.$route.name != route.to &&
            (route.has_child_routes == false ||
              route.has_child_routes1 == false ||
              route.has_child_routes2 == false)
          ) {
            this.$router.push(route.to);
          } else {
            this.current_idx = idx;
          }
        } else if (Action == "SUBROUTE") {
          this.current_sub_idx = idx;
          this.routeItem.current_sub_idx = idx;
          this.$route.name != route.to ? this.$router.push(route.to) : "";
        }
        this.$store.commit("SET_CURRENT_ROUTE", this.routeItem);
      } else {
        Auth.signOut();
        this.$router.push("/");
        this.$store.commit("SET_CURRENT_ROUTE", {});
        this.$store.commit("set_org_user_details", {});
        this.$store.commit("set_org_user_details", {});
      }
    },
    logout() {
      this.exitdialog = true;
      // Auth.signOut();
      // this.$router.push("/");
      // this.$store.commit("SET_CURRENT_ROUTE", {});
      // this.$store.commit("set_org_user_details", {});
      // this.$store.commit("set_org_user_details", {});
    },
  },
};
</script>

<style></style>
