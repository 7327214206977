<template>
  <div>
    <v-card class="overflow-hidden" elevation="0">
      <v-card-title style="font-size: 50px" class="primary--text">
        Recharge</v-card-title
      >
      <v-card-text>
        <div
          align="center"
          class="justify-center"
          style="margin-left: 50px; margin-right: 50px"
        >
          <!-- style="margin-left: 50px; margin-right: 50px" -->
          <v-radio-group
            dense
            v-model="radios"
            row
            class="justify-center"
            align="center"
          >
            <v-card flat @click="radios = 0" width="160" class="ml-4 mr-2">
              <v-img
                contain
                src="@/assets/Trial.png"
                :class="radios == 0 ? 'zoom-efect' : ''"
              ></v-img>
              <v-radio
                v-if="radios == 0"
                class="green"
                color="green"
                label="Select"
                v-model="trialradio"
                style="
                  font-weight: bolder;
                  margin-top: -40px;
                  margin-left: 55px;
                "
              ></v-radio>
              <v-radio
                v-else
                class="white"
                color="white"
                label="Select"
                v-model="trialradio1"
                style="
                  font-weight: bolder;
                  margin-top: -50px;
                  margin-left: 55px;
                "
              ></v-radio>
            </v-card>
            <v-card flat @click="radios = 6" width="160" class="mr-2">
              <v-img
                contain
                :class="radios == 6 ? 'zoom-efect' : ''"
                src="@/assets/Trial Plus.png"
              ></v-img>
              <v-radio
                v-if="radios == 6"
                label="Select"
                class="yellow"
                color="yellow"
                v-model="trialradioplus"
                style="
                  font-weight: bolder;
                  margin-top: -40px;
                  margin-left: 55px;
                "
              ></v-radio>
              <v-radio
                v-else
                label="Select"
                class="white"
                color="white"
                v-model="trialradioplus1"
                style="
                  font-weight: bolder;
                  margin-top: -50px;
                  margin-left: 55px;
                "
              ></v-radio>
            </v-card>

            <v-card flat @click="radios = 1" width="160" class="mr-2">
              <v-img
                contain
                :class="radios == 1 ? 'zoom-efect' : ''"
                src="@/assets/Starter.png"
              >
              </v-img>
              <v-radio
                v-if="radios == 1"
                label="Select"
                class="blue"
                color="blue"
                v-model="starterradio"
                style="
                  font-weight: bolder;
                  margin-top: -40px;
                  margin-left: 55px;
                "
              ></v-radio>
              <v-radio
                v-else
                label="Select"
                class="white"
                color="white"
                v-model="starterradio1"
                style="
                  font-weight: bolder;
                  margin-top: -50px;
                  margin-left: 55px;
                "
              ></v-radio>
            </v-card>

            <v-card flat @click="radios = 2" width="160" class="mr-2">
              <v-img
                contain
                :class="radios == 2 ? 'zoom-efect' : ''"
                src="@/assets/Starter Plus.png"
              ></v-img>
              <v-radio
                v-if="radios == 2"
                class="pink"
                color="pink"
                label="Select"
                v-model="starterplusradio"
                style="
                  font-weight: bolder;
                  margin-top: -40px;
                  margin-left: 55px;
                "
              ></v-radio>
              <v-radio
                v-else
                class="white"
                color="white"
                label="Select"
                v-model="starterplusradio1"
                style="
                  font-weight: bolder;
                  margin-top: -50px;
                  margin-left: 55px;
                "
              ></v-radio>
            </v-card>

            <v-card flat @click="radios = 3" width="160" class="mr-2">
              <v-img
                contain
                :class="radios == 3 ? 'zoom-efect' : ''"
                src="@/assets/Value.png"
              ></v-img>
              <v-radio
                v-if="radios == 3"
                label="Select"
                v-model="valueradio"
                class="orange"
                color="orange"
                style="
                  font-weight: bolder;
                  margin-top: -40px;
                  margin-left: 55px;
                "
              ></v-radio>
              <v-radio
                v-else
                label="Select"
                v-model="valueradio1"
                class="white"
                color="white"
                style="
                  font-weight: bolder;
                  margin-top: -50px;
                  margin-left: 55px;
                "
              ></v-radio>
            </v-card>

            <v-card flat @click="radios = 4" width="160" class="mr-2">
              <v-img
                contain
                :class="radios == 4 ? 'zoom-efect' : ''"
                src="@/assets/Value Plus.png"
              ></v-img>
              <v-radio
                v-if="radios == 4"
                label="Select"
                class="teal"
                color="teal"
                v-model="valueplusradio"
                style="
                  font-weight: bolder;
                  margin-top: -40px;
                  margin-left: 55px;
                "
              ></v-radio>
              <v-radio
                v-else
                label="Select"
                class="white"
                color="white"
                v-model="valueplusradio1"
                style="
                  font-weight: bolder;
                  margin-top: -50px;
                  margin-left: 55px;
                "
              ></v-radio>
            </v-card>
            <v-card flat @click="radios = 5" width="160" class="">
              <v-img
                contain
                :class="radios == 5 ? 'zoom-efect' : ''"
                src="@/assets/Economy.png"
              ></v-img>
              <v-radio
                v-if="radios == 5"
                label="Select"
                class="red"
                color="red"
                v-model="economicalradio"
                style="
                  font-weight: bolder;
                  margin-top: -40px;
                  margin-left: 55px;
                "
              ></v-radio>
              <v-radio
                v-else
                label="Select"
                class="white"
                color="white"
                v-model="economicalradio1"
                style="
                  font-weight: bolder;
                  margin-top: -50px;
                  margin-left: 55px;
                "
              ></v-radio>
            </v-card>
          </v-radio-group>

          <v-row class="my-5" no-gutters>
            <v-card-text align="center" style="font-size: 18px" class="mt-n1">
              <h2>
                Pack Selected :
                {{
                  radios == 0
                    ? "TRIAL"
                    : radios == 1
                    ? "STARTER"
                    : radios == 2
                    ? "STARTER PLUS"
                    : radios == 3
                    ? "VALUE"
                    : radios == 4
                    ? "VALUE PLUS"
                    : radios == 5
                    ? "ECONOMY"
                    : radios == 6
                    ? "TRIAL PLUS"
                    : "--- NONE ---"
                }}
              </h2>
              <div class="mt-5">
                <b
                  >1 scan = 1 Credit, 1 form submission = 1 Credit, Live
                  Tracking 5 pins = 1 Credit</b
                >
              </div>
            </v-card-text>
            <v-card-text class="" align="center">
              <v-btn
                id="rzp-button1"
                :loading="loading"
                :disabled="radios == 7"
                @click="redirectpayment()"
                class="gradient-bg white--text"
                small
                ><v-icon class="mr-2">mdi-credit-card</v-icon> Proceed to pay
              </v-btn>
            </v-card-text>
          </v-row>
        </div>
      </v-card-text>
    </v-card>

    <PaymentSuccessful :paysuccess="paysuccess" />
  </div>
</template>
  <script src="https://checkout.razorpay.com/v1/checkout.js"></script>
  <script>
import PaymentSuccessful from "@/components/Dialogs/PaymentSuccessful.vue";
import { API } from "aws-amplify";
export default {
  components: {
    PaymentSuccessful,
  },
  props: { paymentdialog: Boolean },
  data: () => ({
    paysuccess: false,
    starterradio: 1,
    starterplusradio: 2,
    valueradio: 3,
    valueplusradio: 4,
    economicalradio: 5,
    trialradio: 0,
    starterradio1: 1,
    starterplusradio1: 2,
    valueradio1: 3,
    valueplusradio1: 4,
    economicalradio1: 5,
    trialradioplus: 6,
    trialradioplus1: 0,
    trialradio1: 0,
    radios: 0,
    loading: false,
    pack: "",
    credits: 0,
    amount: 0,
    height: 0,
  }),
  watch: {
    paymentdialog(val) {
      if (val == true) {
        this.height = window.innerHeight;
        this.radios = 7;
        // console.log(this.$store.getters.get_org_details);
      }
    },
    radios(val) {
      if (val == 0) {
        this.pack = "500 CREDITS";
        this.credits = 500;
        this.amount = 295;
      } else if (val == 1) {
        this.pack = "5000 CREDITS ";
        this.credits = 5000;
        this.amount = 2242;
      } else if (val == 2) {
        this.pack = "10000 CREDITS ";
        this.credits = 10000;
        this.amount = 4248;
      } else if (val == 3) {
        this.credits = 25000;
        this.pack = "25000 CREDITS ";
        this.amount = 10030;
      } else if (val == 4) {
        this.credits = 50000;
        this.pack = "50000 CREDITS ";
        this.amount = 18880;
      } else if (val == 5) {
        this.credits = 100000;
        this.pack = "100000 CREDITS ";
        this.amount = 35400;
      } else if (val == 6) {
        this.credits = 2500;
        this.pack = "2500 CREDITS ";
        this.amount = 1180;
      }
    },
  },
  methods: {
    async razorpayResponse(response) {
      if (response.hasOwnProperty("error")) {
        alert("Payment Unsuccessul.");
      } else {
        this.paysuccess = true;
      }
    },
    async redirectpayment() {
      this.loading = true;
      const path = "/prznce_payment";
      const v = {
        headers: {
          "X-API-Key": "Qqmh14V2u475aSAxf0fdc4jdMENPIha13KiagpKT",
        },
        body: {
          organisation_id:
            this.$store.getters.get_org_details[0].organisation_id,
          orderamount: this.amount,
          currency: "INR",
          user_email_id: this.$store.getters.get_useremail,
          pack: this.pack,
          credits_to_be_added: this.credits,
          payment_gateway: "RAZORPAY",
        },
      };
      await API.post("prznce_payment", path, v)
        .then((res) => {
          if (res.Status == "SUCCESS") {
            this.client_secret = res.client_secret;
            this.loading = false;
            // console.log("amount inmside", this.amount);
            var rzp1 = new Razorpay({
              key: "rzp_live_Stc47A8Q8D54jU",
              amount: this.amount,
              currency: "INR",
              name: "Prezence Recharge",
              description: this.pack,
              image:
                "https://is4-ssl.mzstatic.com/image/thumb/Purple114/v4/8b/cb/5b/8bcb5b84-78ab-e2d8-98e5-740d4068559e/source/512x512bb.jpg",
              order_id: this.client_secret,
              handler: this.razorpayResponse,
              prefill: {
                name: "",
                email: this.$store.getters.get_useremail,
                contact: "",
              },
              notes: {
                address: "",
              },
              theme: {
                color: "#f4813f",
              },
              redirect: false,
            });
            rzp1.on("payment.failed", this.razorpayResponse);
            rzp1.open();
          } else {
            this.$store.commit("snackbar/showMessage", {
              content: "Unable to proceed. Try again later",
              color: "red",
            });
          }
          this.loading = false;
        })
        .catch((error) => {
          console.log("EROOR  ", error);
          this.$store.commit("snackbar/showMessage", {
            content: error.errors[0].message,
            color: "red",
          });
          this.loading = false;
        });
    },
    getTheURL(url) {
      setTimeout(function (val) {
        console.warn("Check the function value", val);
        return url;
      }, 3000);
    },
  },
};
</script>
  
  <style scoped>
.zoom-efect {
  transform: scale(1.1);
}

.up {
  transform: translateY(-20px);
  transition: 0.5s ease-out;
}
.trans {
  background-color: white !important;
  opacity: 0.1;
  border-color: transparent !important;
}
</style>
  