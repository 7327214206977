/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const CreateOrganisations = /* GraphQL */ `
  mutation CreateOrganisations($input: CreateOrganisations) {
    CreateOrganisations(input: $input) {
      Message
      organisation_logo
      __typename
    }
  }
`;
export const AddMember = /* GraphQL */ `
  mutation AddMember($input: AddMember) {
    AddMember(input: $input) {
      Message
      __typename
    }
  }
`;
export const UpdateMemberDetails = /* GraphQL */ `
  mutation UpdateMemberDetails($input: UpdateMemberDetails) {
    UpdateMemberDetails(input: $input) {
      Message
      __typename
    }
  }
`;
export const DeactivateAndReactivateMember = /* GraphQL */ `
  mutation DeactivateAndReactivateMember(
    $input: DeactivateAndReactivateMember
  ) {
    DeactivateAndReactivateMember(input: $input) {
      Message
      __typename
    }
  }
`;
export const AddMembersAsAdmin = /* GraphQL */ `
  mutation AddMembersAsAdmin($input: AddMembersAsAdmin) {
    AddMembersAsAdmin(input: $input) {
      Message
      __typename
    }
  }
`;
export const UpdateAdminToMember = /* GraphQL */ `
  mutation UpdateAdminToMember($input: UpdateAdminToMember) {
    UpdateAdminToMember(input: $input) {
      Message
      __typename
    }
  }
`;
export const UpdateAdminUserNotification = /* GraphQL */ `
  mutation UpdateAdminUserNotification($input: UpdateAdminUserNotification) {
    UpdateAdminUserNotification(input: $input) {
      Message
      __typename
    }
  }
`;
export const UpdateOrganisationWorkshift = /* GraphQL */ `
  mutation UpdateOrganisationWorkshift($input: UpdateOrganisationWorkshift) {
    UpdateOrganisationWorkshift(input: $input)
  }
`;
export const ChangeOfOwnerShip = /* GraphQL */ `
  mutation ChangeOfOwnerShip($input: ChangeOfOwnerShip) {
    ChangeOfOwnerShip(input: $input) {
      Message
      __typename
    }
  }
`;
export const EditOrganisationdetails = /* GraphQL */ `
  mutation EditOrganisationdetails($input: EditOrganisationdetails) {
    EditOrganisationdetails(input: $input) {
      Message
      organisation_logo
      __typename
    }
  }
`;
export const OrganisationQrDistanceEdit = /* GraphQL */ `
  mutation OrganisationQrDistanceEdit($input: OrganisationQrDistanceEdit) {
    OrganisationQrDistanceEdit(input: $input) {
      Message
      organisation_logo
      __typename
    }
  }
`;
export const CreateHolidayList = /* GraphQL */ `
  mutation CreateHolidayList($input: CreateHolidayList) {
    CreateHolidayList(input: $input) {
      Message
      __typename
    }
  }
`;
export const DeleteHoliday = /* GraphQL */ `
  mutation DeleteHoliday($input: DeleteHoliday) {
    DeleteHoliday(input: $input)
  }
`;
export const WhatsappNotificationEditForUser = /* GraphQL */ `
  mutation WhatsappNotificationEditForUser(
    $input: WhatsappNotificationEditForUser
  ) {
    WhatsappNotificationEditForUser(input: $input) {
      Message
      __typename
    }
  }
`;
export const CreateLocations = /* GraphQL */ `
  mutation CreateLocations($input: CreateLocations) {
    CreateLocations(input: $input) {
      Message
      location_id
      location_name
      __typename
    }
  }
`;
export const EditLocations = /* GraphQL */ `
  mutation EditLocations($input: EditLocations) {
    EditLocations(input: $input) {
      Message
      location_id
      location_name
      __typename
    }
  }
`;
export const AddQrToLocations = /* GraphQL */ `
  mutation AddQrToLocations($input: AddQrToLocations) {
    AddQrToLocations(input: $input) {
      Message
      location_id
      location_name
      __typename
    }
  }
`;
export const EditSourceLocation = /* GraphQL */ `
  mutation EditSourceLocation($input: EditSourceLocation) {
    EditSourceLocation(input: $input) {
      Message
      location_id
      location_name
      __typename
    }
  }
`;
export const DeleteOrganisationsLocation = /* GraphQL */ `
  mutation DeleteOrganisationsLocation($input: DeleteOrganisationsLocation) {
    DeleteOrganisationsLocation(input: $input) {
      Message
      location_id
      location_name
      __typename
    }
  }
`;
export const TaggedToLocations = /* GraphQL */ `
  mutation TaggedToLocations($input: TaggedToLocations) {
    TaggedToLocations(input: $input) {
      Message
      location_id
      location_name
      __typename
    }
  }
`;
export const BulkUploadusers = /* GraphQL */ `
  mutation BulkUploadusers($input: BulkUploadusers) {
    BulkUploadusers(input: $input)
  }
`;
export const LocationReactivate = /* GraphQL */ `
  mutation LocationReactivate($input: LocationReactivate) {
    LocationReactivate(input: $input) {
      Message
      location_id
      location_name
      __typename
    }
  }
`;
export const PrzncePayment = /* GraphQL */ `
  mutation PrzncePayment($input: PrzncePayment) {
    PrzncePayment(input: $input) {
      Status
      client_secret
      __typename
    }
  }
`;
export const AddDeviceToken = /* GraphQL */ `
  mutation AddDeviceToken($input: AddDeviceToken) {
    AddDeviceToken(input: $input) {
      Message
      __typename
    }
  }
`;
export const UpdateWorkshiftNotification = /* GraphQL */ `
  mutation UpdateWorkshiftNotification($input: UpdateWorkshiftNotification) {
    UpdateWorkshiftNotification(input: $input) {
      Message
      __typename
    }
  }
`;
export const IsReswipeUpdationsGlobally = /* GraphQL */ `
  mutation IsReswipeUpdationsGlobally($input: IsReswipeUpdationsGlobally) {
    IsReswipeUpdationsGlobally(input: $input) {
      Message
      __typename
    }
  }
`;
export const createBoxGlobally = /* GraphQL */ `
  mutation CreateBoxGlobally($input: createBoxGloballyInput) {
    createBoxGlobally(input: $input) {
      Message
      __typename
    }
  }
`;
export const addBoxToLocation = /* GraphQL */ `
  mutation AddBoxToLocation($input: addBoxToLocationInput) {
    addBoxToLocation(input: $input) {
      Message
      location_id
      location_name
      __typename
    }
  }
`;
export const editBoxDetails = /* GraphQL */ `
  mutation EditBoxDetails($input: editBoxDetailsInput) {
    editBoxDetails(input: $input) {
      Message
      location_id
      location_name
      __typename
    }
  }
`;
export const releaseBoxFromOrganisation = /* GraphQL */ `
  mutation ReleaseBoxFromOrganisation($input: releaseBoxFromOrganisationInput) {
    releaseBoxFromOrganisation(input: $input) {
      Message
      location_id
      location_name
      __typename
    }
  }
`;
export const addCardToEmployee = /* GraphQL */ `
  mutation AddCardToEmployee($input: addCardToEmployeeInput) {
    addCardToEmployee(input: $input) {
      Message
      location_id
      location_name
      __typename
    }
  }
`;
export const changeCardIdToAnotherEmployee = /* GraphQL */ `
  mutation ChangeCardIdToAnotherEmployee(
    $input: changeCardIdToAnotherEmployeeInput
  ) {
    changeCardIdToAnotherEmployee(input: $input) {
      Message
      location_id
      location_name
      __typename
    }
  }
`;
export const createWorkshiftForOrganisation = /* GraphQL */ `
  mutation CreateWorkshiftForOrganisation(
    $input: createWorkshiftForOrganisationInput
  ) {
    createWorkshiftForOrganisation(input: $input)
  }
`;
export const editWorkshiftForOrganisation = /* GraphQL */ `
  mutation EditWorkshiftForOrganisation($input: editWorkshiftForOrganisation) {
    editWorkshiftForOrganisation(input: $input)
  }
`;
export const activateAndReactiveaytedWorkshiftForOrganisation = /* GraphQL */ `
  mutation ActivateAndReactiveaytedWorkshiftForOrganisation(
    $input: activateAndReactiveaytedWorkshiftForOrganisationInput
  ) {
    activateAndReactiveaytedWorkshiftForOrganisation(input: $input)
  }
`;
export const userAddToTheWorkshift = /* GraphQL */ `
  mutation UserAddToTheWorkshift($input: userAddToTheWorkshiftInput) {
    userAddToTheWorkshift(input: $input)
  }
`;
export const removeUsersFromShift = /* GraphQL */ `
  mutation RemoveUsersFromShift($inpiut: userAddToTheWorkshiftInput) {
    removeUsersFromShift(inpiut: $inpiut)
  }
`;
export const add_beacon_to_location = /* GraphQL */ `
  mutation Add_beacon_to_location($input: AddBeaconToLocationInput) {
    add_beacon_to_location(input: $input) {
      Message
      __typename
    }
  }
`;
export const edit_and_delete_reactivate_beacon_details = /* GraphQL */ `
  mutation Edit_and_delete_reactivate_beacon_details(
    $input: EditAndDeleteReactivateBeaconDetailsInput
  ) {
    edit_and_delete_reactivate_beacon_details(input: $input) {
      Message
      __typename
    }
  }
`;
export const send_notification_for_the_employee = /* GraphQL */ `
  mutation Send_notification_for_the_employee(
    $input: SendNotificationForTheEmployeeInput
  ) {
    send_notification_for_the_employee(input: $input) {
      Message
      __typename
    }
  }
`;
export const GetAddingMemebersToWorkShift = /* GraphQL */ `
  mutation GetAddingMemebersToWorkShift(
    $input: GetAddingMemebersToWorkShiftInput
  ) {
    GetAddingMemebersToWorkShift(input: $input)
  }
`;
export const generation_beacon_organisation = /* GraphQL */ `
  mutation Generation_beacon_organisation(
    $input: GenerationBeaconOrganisationInput
  ) {
    generation_beacon_organisation(input: $input) {
      Message
      beacon {
        beacon_id
        beacon_mac_address
        beacon_created_on
        beacon_email_created_by
        beacon_major
        beacon_minor
        beacon_name
        beacon_status
        is_beacon_calimed
        is_shipped
        location_id
        organisation_id
        __typename
      }
      __typename
    }
  }
`;
export const claiming_beacon_to_location_organisation = /* GraphQL */ `
  mutation Claiming_beacon_to_location_organisation(
    $input: ClaimingBeaconToLocationOrganisationInput
  ) {
    claiming_beacon_to_location_organisation(input: $input) {
      Message
      __typename
    }
  }
`;
export const delete_face_from_collection = /* GraphQL */ `
  mutation Delete_face_from_collection($input: DeleteFaceFromCollectionInput) {
    delete_face_from_collection(input: $input) {
      Message
      organisation_logo
      __typename
    }
  }
`;
export const give_access_for_card_to_box = /* GraphQL */ `
  mutation Give_access_for_card_to_box($input: GiveAccessForCardToBoxInput) {
    give_access_for_card_to_box(input: $input) {
      Message
      __typename
    }
  }
`;
export const box_restart_organisation = /* GraphQL */ `
  mutation Box_restart_organisation($input: BoxRestartOrganisationInput) {
    box_restart_organisation(input: $input) {
      Message
      __typename
    }
  }
`;
export const relay_open_for_box_organisation = /* GraphQL */ `
  mutation Relay_open_for_box_organisation(
    $input: RelayOpenForBoxOrganisationInput
  ) {
    relay_open_for_box_organisation(input: $input) {
      Message
      __typename
    }
  }
`;
export const is_qr_updation_globally = /* GraphQL */ `
  mutation Is_qr_updation_globally($input: IsQrUpdationGloballyInput) {
    is_qr_updation_globally(input: $input) {
      Message
      __typename
    }
  }
`;
export const is_face_updation_globally = /* GraphQL */ `
  mutation Is_face_updation_globally($input: IsFaceUpdationGloballyInput) {
    is_face_updation_globally(input: $input) {
      Message
      __typename
    }
  }
`;
export const is_beacon_updation_globally = /* GraphQL */ `
  mutation Is_beacon_updation_globally($input: IsBeaconUpdationGloballyInput) {
    is_beacon_updation_globally(input: $input) {
      Message
      __typename
    }
  }
`;
export const scans_settings = /* GraphQL */ `
  mutation Scans_settings($input: ScansSettingsInput) {
    scans_settings(input: $input) {
      Status
      Message
      __typename
    }
  }
`;
export const create_visitor_location = /* GraphQL */ `
  mutation Create_visitor_location($input: CreateVisitorLocationInputs) {
    create_visitor_location(input: $input) {
      Status
      Message
      __typename
    }
  }
`;
export const approve_setting_visitor_organisation_id = /* GraphQL */ `
  mutation Approve_setting_visitor_organisation_id(
    $input: ApproveSettingVisitorOrganisationIdInput
  ) {
    approve_setting_visitor_organisation_id(input: $input) {
      Status
      Message
      __typename
    }
  }
`;
export const approve_or_deny_visitors = /* GraphQL */ `
  mutation Approve_or_deny_visitors($input: ApproveOrDenyVisitorsInput) {
    approve_or_deny_visitors(input: $input) {
      Status
      Message
      __typename
    }
  }
`;
export const scan_any_where_response_form_list_or_edit = /* GraphQL */ `
  mutation Scan_any_where_response_form_list_or_edit(
    $input: ScanAnyWhereResponseFormListOrEdit
  ) {
    scan_any_where_response_form_list_or_edit(input: $input)
  }
`;
export const daily_report = /* GraphQL */ `
  mutation Daily_report($input: DailyReportInput) {
    daily_report(input: $input)
  }
`;
export const monthReport = /* GraphQL */ `
  mutation MonthReport($input: MonthReportInput) {
    monthReport(input: $input)
  }
`;
export const getEarlySalaryEditAndList = /* GraphQL */ `
  mutation GetEarlySalaryEditAndList($input: GetEarlySalaryEditAndListInput) {
    getEarlySalaryEditAndList(input: $input)
  }
`;
export const approve_or_reject_flow = /* GraphQL */ `
  mutation Approve_or_reject_flow($input: ApproveOrRejectInput) {
    approve_or_reject_flow(input: $input)
  }
`;
export const proof_of_prezence_settings = /* GraphQL */ `
  mutation Proof_of_prezence_settings($input: ProofOfPrezenceSettingsInput) {
    proof_of_prezence_settings(input: $input) {
      Status
      Message
      __typename
    }
  }
`;
export const approval_for_datacorrection = /* GraphQL */ `
  mutation Approval_for_datacorrection($input: ApprovalForDatacorrectionInput) {
    approval_for_datacorrection(input: $input)
  }
`;
export const data_correction_list_or_edit = /* GraphQL */ `
  mutation Data_correction_list_or_edit($input: DataCorrectionListOrEditInput) {
    data_correction_list_or_edit(input: $input)
  }
`;
export const early_salary_reports = /* GraphQL */ `
  mutation Early_salary_reports($input: EarlySalaryReportsInput) {
    early_salary_reports(input: $input)
  }
`;
export const create_forms = /* GraphQL */ `
  mutation Create_forms($input: CreateFormsInput) {
    create_forms(input: $input)
  }
`;
export const delete_forms = /* GraphQL */ `
  mutation Delete_forms($input: DeleteFormsInput) {
    delete_forms(input: $input)
  }
`;
export const edit_forms = /* GraphQL */ `
  mutation Edit_forms($input: EditFormsInput) {
    edit_forms(input: $input)
  }
`;
export const approve_or_reject_leave = /* GraphQL */ `
  mutation Approve_or_reject_leave($input: ApproveOrRejectLeaveInput) {
    approve_or_reject_leave(input: $input) {
      Status
      Message
      __typename
    }
  }
`;
export const customerDeactivateEdit = /* GraphQL */ `
  mutation CustomerDeactivateEdit($input: CustomerDeactivateEditInput) {
    customerDeactivateEdit(input: $input)
  }
`;
export const approve_all_the_flows = /* GraphQL */ `
  mutation Approve_all_the_flows($input: ApproveAllTheFlowsInput) {
    approve_all_the_flows(input: $input) {
      Message
      Status
      __typename
    }
  }
`;
export const face_swipes = /* GraphQL */ `
  mutation Face_swipes($input: FaceSwipesInput) {
    face_swipes(input: $input) {
      Message
      QRStatus
      __typename
    }
  }
`;
export const create_delete_designations = /* GraphQL */ `
  mutation Create_delete_designations($input: CreateDeleteDesignationsInput) {
    create_delete_designations(input: $input)
  }
`;
export const create_delete_groups = /* GraphQL */ `
  mutation Create_delete_groups($input: CreateDeleteGroupssInput) {
    create_delete_groups(input: $input)
  }
`;
export const tag_untag_forms = /* GraphQL */ `
  mutation Tag_untag_forms($input: TagUntagFormsInput) {
    tag_untag_forms(input: $input)
  }
`;
export const create_delete_employee_type = /* GraphQL */ `
  mutation Create_delete_employee_type($input: CreateDeleteEmployeeTypeInput) {
    create_delete_employee_type(input: $input)
  }
`;
export const add_or_remove_user_group = /* GraphQL */ `
  mutation Add_or_remove_user_group($input: AddOrRemoveUserGroupInput) {
    add_or_remove_user_group(input: $input)
  }
`;
export const all_weekly_kind_report = /* GraphQL */ `
  mutation All_weekly_kind_report($input: AllWeeklyKindReportInput) {
    all_weekly_kind_report(input: $input)
  }
`;
export const export_employee = /* GraphQL */ `
  mutation Export_employee($input: ExportEmployeeInput) {
    export_employee(input: $input)
  }
`;
export const onboard_org_settings = /* GraphQL */ `
  mutation Onboard_org_settings($input: EnboardOrgSettings) {
    onboard_org_settings(input: $input) {
      Status
      Message
      __typename
    }
  }
`;
export const approveRejectLeave = /* GraphQL */ `
  mutation ApproveRejectLeave($input: ApproveOrRejectLeaveInput) {
    approveRejectLeave(input: $input) {
      Status
      Message
      __typename
    }
  }
`;
export const activateDeactiveUser = /* GraphQL */ `
  mutation ActivateDeactiveUser($input: activateDeactiveUserInput) {
    activateDeactiveUser(input: $input) {
      Status
      Message
      __typename
    }
  }
`;
