<template>
  <div>
    <v-dialog v-model="otpverificarionNumber" persistent width="390px">
      <v-card class="overflow-hidden">
        <v-toolbar dense class="gradient-bg elevation-0 white--text">
          <v-toolbar-title>Phone Number Verification</v-toolbar-title
          ><v-spacer />
          <v-icon @click="close_dialog()" class="white--text mr-n3"
            >mdi-close</v-icon
          >
        </v-toolbar>
        <v-card-text class="mt-4">
          <v-form ref="form">
            <label class="ml-7 font-weight-medium">Enter PhoneNumber OTP</label>
            <v-otp-input
              v-model="phoneCode"
              :rules="otp_rules"
              :key="otpverificarionNumber"
              class="mr-6 ml-6 mt-4"
            />
          </v-form>
          <div class="ml-8 mt-4 font-weight-medium">
            Resend OTP in ({{ timer }}s)
            <v-icon
              v-if="!timerRunning"
              color="#EF6C00"
              class="mb-2 ml-2"
              id="sign-in-button"
              @click="restartTimer"
              >mdi-refresh</v-icon
            >
          </div>

          <!-- @finish="submitcode()" -->
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            small
            class="gradient-bg white--text mb-2 ml-2"
            @click="check_phone"
            :loading="phloading"
            id="sign-in-button"
            >Verify</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
/* eslint-disable */

import { initializeApp } from "firebase/app";
import {
  getAuth,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from "firebase/auth";
// import { Auth } from "aws-amplify";

import axios from "axios";
export default {
  props: {
    otpverificarionNumber: Boolean,
    phoneitems: Object,
    sessiondata: String,
  },
  data() {
    return {
      otp_rules: [
        (v) => /^\d+$/.test(v) || "OTP must contain only numbers",
        (v) => v.length != 0 || "OTP cannot be empty",
        (v) => v.length == 6 || "Enter Correct OTP",
      ],
      timer: 60,
      phloading: false,
      phoneCode: "",
      user_full_contact_number: "",
      timerRunning: false,
      sessiondata2: "",
    };
  },
  watch: {
    otpverificarionNumber: {
      async handler() {
        // console.log(this.otpverificarionNumber );
        if (this.otpverificarionNumber == true) {
          this.phoneCode = "";
          this.user_full_contact_number =
            "+" + this.phoneitems.CountryCode + this.phoneitems.PhoneNumber;
          // console.log(this.phoneitems);
          // await this.callSubmit();
          this.startTimer();
          this.sessiondata2 = this.sessiondata;
        }
      },
      immediate: true,
    },
  },
  methods: {
    async phoneotp() {
      axios({
        method: "POST",
        url: "https://liql60b1h9.execute-api.ap-south-1.amazonaws.com/prezence/verifyGenerateOtp",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          command: "otpGenerateForUser",
          action_type: "OTP_GENERATOR",
          contact_number: this.user_full_contact_number,
        },
      })
        .then((res) => {
          if (res.data.Status == "SUCCESS") {
            this.sessiondata2 = "";

            this.sessiondata2 = res.data.session;
            this.$store.commit("snackbar/showMessage", {
              content: "OTP Sent Successfully",
              color: "green",
            });
          } else {
            this.sessiondata2 = "";
          }
        })
        .catch((error) => {
          if (error.response) {
            // Server responded with a status other than 2xx
            console.error("Response error:", error.response.data);
          } else if (error.request) {
            // Request was made but no response received
            console.error("Request error:", error.request);
          } else {
            // Something happened in setting up the request
            console.error("Error:", error.message);
          }
        });
    },
    async callSubmit() {
      this.isLoading = true;
      let firebaseConfig = {
        apiKey: "AIzaSyCF6eLUPT_dElBLgiYIf6WcP_kggfDMNQ0",
        authDomain: "prezence-f23d6.firebaseapp.com",
        projectId: "prezence-f23d6",
        storageBucket: "prezence-f23d6.appspot.com",
        messagingSenderId: "952543398854",
        appId: "1:952543398854:web:950f282f98afc0760500c7",
        measurementId: "G-6WKT1RGKLJ",
      };
      const app = initializeApp(firebaseConfig);
      const auth = getAuth(app);
      auth.languageCode = "en";
      // window.recaptchaVerifier = new RecaptchaVerifier(
      //   "sign-in-button",
      //   {
      //     size: "invisible",
      //   },
      //   auth
      // );
      const phoneNumber =
        (this.phoneitems.CountryCode.toString().includes("+") == false
          ? "+" + this.phoneitems.CountryCode
          : this.phoneitems.CountryCode) + this.phoneitems.PhoneNumber;
      if (!this.recaptchaVerifier) {
        this.recaptchaVerifier = new RecaptchaVerifier(
          "sign-in-button",
          {
            size: "invisible",
            callback: (e) => {
              console.log(e);
            },
          },
          auth
        );
      }
      if (this.recaptchaWidgetId !== "") {
        this.recaptchaVerifier.render().then((widgetId) => {
          if (
            typeof grecaptcha !== "undefined" &&
            typeof grecaptcha.reset === "function"
          ) {
            grecaptcha.reset(widgetId);
          }
          this.recaptchaWidgetId = widgetId;
        });
      } else {
        this.recaptchaVerifier.render().then((widgetId) => {
          this.recaptchaWidgetId = widgetId;
        });
      }
      this.phloading = false;
      // const appVerifier = new RecaptchaVerifier(
      //   "sign-in-button",
      //   {
      //     size: "invisible",
      //   },
      //   auth
      // );
      // console.log("1", auth, phoneNumber, this.recaptchaVerifier);
      // const appVerifier = window.recaptchaVerifier;
      // console.log(`phoneNumber=${phoneNumber} appVerifier=${appVerifier}`);
      // console.log(auth);
      await signInWithPhoneNumber(auth, phoneNumber, this.recaptchaVerifier)
        .then((confirmationResult) => {
          window.confirmationResult = confirmationResult;
          this.$store.commit("snackbar/showMessage", {
            content: "OTP Sent Successfully",
            color: "green",
          });
        })
        .catch((error) => {
          console.log("errr", error);
          this.$store.commit("snackbar/showMessage", {
            content: error,
            color: "red",
          });
          // if (error) {
          // window.recaptchaVerifier.render().then((widgetId) => {
          // grecaptcha.reset(widgetId);
          // });
          //) this.callSubmit();
          // });
          this.isLoading = false;
          this.phloading = false;

          // if (error) {

          // }
        });
    },
    async callcaptcha() {
      this.phloading = true;

      const code = this.phoneCode;
      axios({
        method: "POST",
        url: "https://liql60b1h9.execute-api.ap-south-1.amazonaws.com/prezence/verifyGenerateOtp",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          command: "otpGenerateForUser",
          action_type: "OTP_GENERATOR",
          contact_number: code,
          session: this.sessiondata2,
        },
      })
        .then((res) => {
          if (res.data.Status == "SUCCESS") {
            this.$store.commit("snackbar/showMessage", {
              content: "OTP verified Successfully!",
              color: "green",
            });
            this.$emit("clicked", 4);
          } else {
            this.phloading = false;
            // console.log(res,'s');
            this.$emit("errorMsg", res.data.errorMessage);
          }
        })
        .catch((err) => {
          console.log(err);
          this.phloading = false;
          this.$emit("errorMsg", err.errors[0].message);
        });
    },
    callcaptcha2() {
      this.phloading = true;

      const code = this.phoneCode;
      window.confirmationResult
        .confirm(code)
        .then((result) => {
          // User signed in successfully.
          this.phloading = false;
          // console.log("SUCES");
          this.$store.commit("snackbar/showMessage", {
            content: "OTP verified Successfully!",
            color: "green",
          });
          this.$emit("clicked", 4);
        })
        .catch((error) => {
          if (error) {
            this.$store.commit("snackbar/showMessage", {
              content: "Code Entered is incorrect. Try again",
              color: "red",
            });
            this.phloading = false;
          }
          this.phloading = false;

          // User couldn't sign in (bad verification code?)
          // ...
        });
    },
    close_dialog() {
      this.$refs.form.reset();
      this.phoneCode = "";
      this.timer = 60;
      this.timerRunning = false;
      this.$emit("clicked", 2);
    },
    check_phone() {
      if (this.$refs.form.validate()) {
        // this.callcaptcha();
        this.check_phonenumber();
      } else {
        this.$emit("clicked", 1);
      }
    },
    async check_phonenumber() {
      this.phloading = true;
      axios({
        method: "POST",
        url: "https://liql60b1h9.execute-api.ap-south-1.amazonaws.com/prezence/verifyGenerateOtp",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          command: "otpGenerateForUser",
          action_type: "OTP_VERIFY", //EMAIL //PHONE
          otp: this.phoneCode,
          contact_number: this.user_full_contact_number,
          session: this.sessiondata,
        },
      })
        .then((res) => {
          if (res.data.Status == "SUCCESS") {
            this.$store.commit("snackbar/showMessage", {
              content: "OTP verified Successfully!",
              color: "green",
            });
            this.$emit("clicked", 4);
            // this.$emit("successMsg", res.data.Message);

            // this.phloading = false;
          } else {
            this.phloading = false;
            // console.log(res.data.Message,'ssdsdds');
            
            this.$emit("errorMsg", res.data.Message);
          }
        })
        .catch((err) => {
          console.log(err);
          this.phloading = false;
          this.$emit("errorMsg", err.errors[0].message);
        });
    },
    startTimer() {
      this.timerRunning = true;
      this.timer = 60;
      this.countdown();
    },

    restartTimer() {
      // this.callSubmit();
      // this.resend_otp();
      this.timer = 60;
      this.phoneotp();
      this.timerRunning = true;
    },

    countdown() {
      if (this.timer > 0) {
        setTimeout(() => {
          this.timer--;
          this.countdown();
        }, 1000);
      } else {
        this.timerRunning = false;
      }
    },
    // async resend_otp() {
    //   axios({
    //     method: "POST",
    //     url: "https://liql60b1h9.execute-api.ap-south-1.amazonaws.com/prezence/email_phone_avaliabilty_check_v2 ",
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //     data: {
    //       command: "emailPhoneAvaliabiltyCheckV2",
    //       action_type: "PHONE", //EMAIL //PHONE
    //       user_full_contact_number: this.user_full_contact_number,
    //     },
    //   })
    //     .then((res) => {
    //       // console.log(res);
    //       if (res.data.Status == "SUCCESS") {
    //         this.$emit("clicked", 3);
    //       } else {
    //         console.log(res);
    //       }
    //     })
    //     .catch((err) => {
    //       console.log(err);

    //       this.$emit("errorMsg", err.errors[0].message);
    //     });
    // },
  },
};
</script>

<style>
</style>