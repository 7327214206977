<template>
  <div>
    <v-dialog v-model="exitdialog" persistent width="300px">
      <v-card class="overflow-hidden">
        <v-toolbar dense class="gradient-bg elevation-0 white--text">
          <v-toolbar-title>Log out</v-toolbar-title><v-spacer />
          <!-- <v-icon @click="close_dialog()" class="white--text mr-n3"
            >mdi-close</v-icon
          > -->
        </v-toolbar>
        <v-card-text
          align="center"
          style="font-weight: bold; text-align: justify"
        >
          <div class="mt-5 ml-2">Are you sure you want to log out ?</div>
        </v-card-text>
        <div class="d-flex justify-center mb-3">
          <v-card-actions class="">
            <v-btn
              color="primary"
              class="white--text"
              @click="close_dialog()"
              small
              >No</v-btn
            >
            <v-btn
              color="primary"
              class="white--text"
              @click="logout_Confirmation()"
              small
              >Yes</v-btn
            >
          </v-card-actions>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>
        <script>
import { Auth } from "aws-amplify";
export default {
  components: {},
  props: {
    exitdialog: Boolean,
    rowinfo: Object,
  },
  data: () => ({}),
  watch: {
    exitdialog: {
      handler() {
        {
          //   console.log(this.rowinfo);
        }
      },
      immediate: true,
    },
  },
  methods: {
    close_dialog() {
      this.$emit("clicked", 0);
    },
    logout_Confirmation() {
      Auth.signOut();
      this.$router.push("/");
      this.$store.commit("SET_CURRENT_ROUTE", {});
      this.$store.commit("set_org_user_details", {});
      this.$store.commit("set_org_user_details", {});
    },
  },
};
</script>