<template>
  <div>
    <v-app
      :style="{
        'background-image': 'url(' + require('@/assets/bg.jpg') + ')',
      }"
      style="background-size: cover"
    >
      <v-container class="fill-height" fluid>
        <v-card class="mx-auto" height="410" width="400">
          <v-row>
            <v-col cols="12">
              <div class="d-flex justify-center">
                <v-img
                  max-width="80"
                  max-height="100"
                  class="mt-5"
                  src="@/assets/PrezencePrint.png"
                  @click="showLog"
                >
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="green"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </div>
              <v-window v-model="steps" touchless>
                <v-window-item :value="1">
                  <v-card-text>
                    <h3 class="text-center font-weight mb-2">Welcome Back!</h3>
                    <h4 class="text-center font-weight mb-2">
                      We're so excited to see you again!
                    </h4>
                    <v-form>
                      <div v-if="otpVisible == 0">
                        <v-text-field
                          dense
                          outlined
                          label="Email"
                          v-model="userEmail"
                          :rules="[rules.required, rules.email]"
                          required
                          append-icon="mdi-email"
                          type="text"
                          color="primary"
                          @keyup.enter="validate_sign_in('otp')"
                          style="font-size: 13px"
                        >
                        </v-text-field>
                      </div>
                      <div v-if="otpVisible == 1">
                        <v-text-field
                          dense
                          readonly
                          outlined
                          label="Email"
                          v-model="userEmail"
                          :rules="[rules.required, rules.email]"
                          required
                          append-icon="mdi-email"
                          type="text"
                          color="primary"
                          style="font-size: 13px"
                        >
                        </v-text-field>
                      </div>
                      <div v-show="otpVisible == 1">
                        <v-row no-gutters>
                          <v-otp-input
                            v-model="otpInput"
                            :ref="otpRef"
                            :key="otpverificarionNumber"
                            length="6"
                          ></v-otp-input>
                          <div class="ml-2 font-weight-medium">
                            Resend OTP in ({{ timer }}s)
                            <v-icon
                              v-if="!timerRunning"
                              color="#EF6C00"
                              class="mb-2 ml-2 mt-1"
                              @click="restartTimer('otp')"
                              >mdi-refresh</v-icon
                            >
                          </div>

                          <!-- <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                color="primary"
                                dark
                                v-bind="attrs"
                                v-on="on"
                                @click="validate_sign_in('otp')"
                              >
                                mdi-refresh-circle
                              </v-icon>
                            </template>
                            <span>Resend OTP</span>
                          </v-tooltip> -->
                        </v-row>
                      </div>
                      <div v-if="otpVisible == 0">
                        <v-btn
                          block
                          dark
                          class="mt-4 elevation-0 text-capitalize cardCss gradient-bg white--text"
                          :loading="loading1"
                          @click="validate_sign_in('otp')"
                          >Send OTP</v-btn
                        >
                        <v-row class="mt-2" no-gutters>
                          <div>
                            Need an account ?
                            <v-btn
                              x-small
                              text
                              color="#ef3f6e"
                              @click="sign_up_action()"
                              ><b><u>Register</u></b></v-btn
                            >
                          </div>
                        </v-row>
                      </div>
                      <div v-if="otpVisible == 1">
                        <v-btn
                          block
                          dark
                          class="mt-4 text-capitalize gradient-bg white--text"
                          :loading="loading1"
                          @click="validate_sign_in('login')"
                          >Login</v-btn
                        >
                      </div>
                    </v-form>
                  </v-card-text>
                </v-window-item>
              </v-window>
              <!-- </v-col> -->
              <!-- </v-card> -->
            </v-col>
          </v-row>
        </v-card>
      </v-container>
    </v-app>
    <v-dialog v-model="Reactivatedailog" persistent width="390px">
      <v-card class="overflow-hidden">
        <v-toolbar dense class="gradient-bg elevation-0 white--text">
          <v-toolbar-title>Reactivate Organization</v-toolbar-title><v-spacer />
          <v-icon @click="close_dialog()" class="white--text mr-n3"
            >mdi-close</v-icon
          >
        </v-toolbar>
        <v-card-text
          align="center"
          style="font-weight: bold; text-align: justify"
        >
          <div class="mt-5">
            Would you like to Reactivate your Organization?
          </div>
        </v-card-text>
        <div class="d-flex justify-center mb-3">
          <v-card-actions class="">
            <v-btn
              color="primary"
              class="white--text"
              @click="close_dialog()"
              small
              >No</v-btn
            >
            <v-btn
              color="primary"
              class="white--text"
              :loading="buttonload"
              @click="deactivate_reactivate_location()"
              small
              >Yes</v-btn
            >
          </v-card-actions>
        </div>
      </v-card>
    </v-dialog>
    <SnackBar :SnackBarComponent="SnackBarComponent" />
  </div>
</template>

<script>
/* eslint-disable */
import SnackBar from "@/components/SnackBar.vue";
import { customerDeactivateEdit } from "@/graphql/mutations.js";
import { GetCurrentUserDetails } from "@/graphql/queries.js";
import { API, graphqlOperation } from "aws-amplify";
import { Auth } from "aws-amplify";
export default {
  components: {
    SnackBar,
  },

  data() {
    return {
      userEmail: "",
      SnackBarComponent: {},
      Getorgcurrent: {},
      GetCurrentUserObject2: {},
      GetCurrentOrgObject: {},
      steps: null,
      loading1: false,
      otpInput: "",
      otpverificarionNumber: false,
      otpVisible: 0,
      userDetails: {},
      buttonload: false,
      isEmailEntered: false,
      timerRunning: false,
      timer: 120,
      otpRef: "otpField",
      rules: {
        required: (value) => !!value || "Required.",
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid Email";
        },
      },
      Reactivatedailog: false,
    };
  },
  created() {},
  methods: {
    showLog() {
      window.location.href = "https://www.prznce.com/";
    },
    startTimer() {
      this.timerRunning = true;
      this.timer = 120;
      this.countdown();
    },
    restartTimer() {
      this.validate_sign_in("otp");
      this.timer = 120;
      this.countdown2();

      this.timerRunning = true;
    },
    countdown() {
      if (this.timer > 0) {
        setTimeout(() => {
          this.timer--;
          this.countdown();
        }, 1000);
      } else {
        this.timerRunning = false;
      }
    },
    countdown2() {
      if (this.timer > 0) {
        setTimeout(() => {
          this.timer--;
          this.countdown2();
        }, 3000);
      } else {
        this.timerRunning = false;
      }
    },
    async deactivate_reactivate_location() {
      this.buttonload = true;
      try {
        let result = await API.graphql(
          graphqlOperation(customerDeactivateEdit, {
            input: {
              organisation_id:
                this.$store.getters.get_org_details[0].organisation_id,
              customer_activation: "ACTIVE",
            },
          })
        );
        var response = JSON.parse(result.data.customerDeactivateEdit);
        if (response.Status == "SUCCESS") {
          this.buttonload = false;
          await this.GetCurrentUserDetailslogin();
          // setTimeout(() => {
          //   if (
          //     this.GetCurrentOrgObject[0] == undefined || (this.GetCurrentOrgObject[0] != undefined &&
          //   this.GetCurrentOrgObject[0].is_customer_recharge == true)
          // ) {
          //   this.$router.push("LoginRecharge");
          // } else {
          //   this.$store.commit("snackbar/showMessage", {
          //     content: "Login Successful!",
          //     color: "green",
          //   });
          //   setTimeout(() => {
          //     this.$router.push("Dashboard");
          //   }, 2000);
          // }
          // this.Reactivatedailog = false;
          //   }, 3000);
        } else {
          this.$store.commit("snackbar/showMessage", {
            content: response.Message,
            color: "green",
          });
        }

        // console.log(result.data.customerDeactivateEdit);
      } catch (err) {
        this.$store.commit("snackbar/showMessage", {
          content: err.errors[0].message,
          color: "red",
        });
        this.buttonload = false;
      }
    },
    close_dialog() {
      this.Reactivatedailog = false;
      this.loading1 = false;
      Auth.signOut();
      this.$router.push("/");
      this.$store.commit("SET_CURRENT_ROUTE", {});
    },
    async send_otp() {
      try {
        const user = await Auth.signIn(this.userEmail)

          .then((user) => {
            this.userDetails = user;
          })
          .catch((err) => {
            this.SnackBarComponent = {
              SnackbarVmodel: true,
              SnackbarColor: "red",
              SnackbarText: "Couldn't find your Prezence account",
              timeout: 5000,
              Top: true,
            };
          });
      } catch (error) {}
    },
    validate_sign_in(val) {
      var valid = true;

      if (this.userEmail == "") {
        valid = false;
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          SnackbarText: "Email  Is Required",
          timeout: 5000,
          Top: true,
        };
      }
      if (valid) {
        if (val == "otp") {
          this.startTimer();
          this.generate_otp();
        } else {
          this.login_with_otp();
        }
      }
    },
    focusOnOTPField() {
      this.$nextTick(() => {
        setTimeout(() => {
          this.$refs[this.otpRef].focus();
        }, 100); // Adjust the delay as needed (100ms in this case)
      });
    },
    async generate_otp() {
      this.loading1 = true;
      try {
        const user = await Auth.signIn(this.userEmail)
          .then((user) => {
            this.userDetails = user;

            this.SnackBarComponent = {
              SnackbarVmodel: true,
              SnackbarColor: "green",
              SnackbarText: "OTP Sent Successfully",
              timeout: 5000,
              Top: true,
            };
            this.focusOnOTPField();
            this.otpVisible = 1;

            this.loading1 = false;
          })
          .catch((err) => {
            this.SnackBarComponent = {
              SnackbarVmodel: true,
              SnackbarColor: "red",
              SnackbarText: "Couldn't find your Prezence account!!",
              timeout: 5000,
              Top: true,
            };
            this.loading1 = false;
          });
      } catch (error) {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          SnackbarText: error.message,
          timeout: 5000,
          Top: true,
        };
        this.loading1 = false;
      }
    },
    sign_up_action() {
      this.$router.push("/Signup");
    },

    async login_with_otp() {
      this.loading1 = true;

      try {
        await Auth.sendCustomChallengeAnswer(this.userDetails, this.otpInput);
        await Auth.currentAuthenticatedUser()
          .then((user) => {
            this.$store.commit("SET_AUTH", true);
            this.$store.commit("SET_USEREMAIL", this.userEmail);
            this.$store.commit("SET_CURRENT_ROUTE", 0);
            this.GetCurrentUserDetailslogin();
            //   setTimeout(() => {
            //     if (this.GetCurrentOrgObject[0].customer_activation == "ACTIVE") {
            //       if (this.GetCurrentOrgObject[0].is_customer_recharge == true) {
            //         setTimeout(() => {
            //           this.$router.push("LoginRecharge");
            //         }, 1000);
            //       } else {
            //         this.$store.commit("snackbar/showMessage", {
            //           content: "Login Successful!",
            //           color: "green",
            //         });
            //         setTimeout(() => {
            //           this.$router.push("Dashboard");
            //         }, 1000);
            //       }
            //     } else {
            //       this.loading1 = false;
            //       this.Reactivatedailog = true;
            //     }
            //   }, 1000);
          })
          .catch((err) => {
            console.log(err);

            this.otpverificarionNumber = true;
            if (this.otpverificarionNumber == true) {
              this.otpInput = "";
              this.otpverificarionNumber == false;
            }
            this.SnackBarComponent = {
              SnackbarVmodel: true,
              SnackbarColor: "red",
              SnackbarText: "Invalid OTP",
              timeout: 5000,
              Top: true,
            };

            this.loading1 = false;

            this.$forceUpdate();
            // this.$refs.otp.focus();
          });
      } catch (error) {
        // this.otpInput = "";
        this.$forceUpdate();
        if (error.message == "Incorrect username or password.") {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            SnackbarText: "Invalid OTP",
            timeout: 5000,
            Top: true,
          };
          this.otpInput = "";
        } else if (error.message == "Challenge response cannot be empty") {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            SnackbarText: "Please Enter OTP Manually",
            timeout: 5000,
            Top: true,
          };
        } else if (error.message == "Invalid session for the user.") {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            SnackbarText:
              "Multiple Attempts, Please Resend OTP and Login again",
            timeout: 5000,
            Top: true,
          };
        } else {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            SnackbarText: error.message,
            timeout: 5000,
            Top: true,
          };
        }
        this.loading1 = false;
      }
    },
    async GetCurrentUserDetailslogin() {
      try {
        let result = await API.graphql(
          graphqlOperation(GetCurrentUserDetails, {
            input: {
              user_email_id: this.userEmail.toLowerCase(),
            },
          })
        );
        this.Getorgcurrent = result.data.GetCurrentUserDetails.orgdetails;
        this.GetCurrentUserObject2 =
          result.data.GetCurrentUserDetails.UserDetails;
        this.GetCurrentOrgObject = result.data.GetCurrentUserDetails.orgdetails;

        this.$store.commit("set_org_user_details", this.GetCurrentUserObject2);
        this.$store.commit("set_org_details", this.Getorgcurrent);

        setTimeout(() => {
          if (this.GetCurrentOrgObject[0].customer_activation == "ACTIVE") {
            if (this.GetCurrentOrgObject[0].is_customer_recharge == true) {
              setTimeout(() => {
                this.$router.push("LoginRecharge");
              }, 1000);
            } else {
              this.$store.commit("snackbar/showMessage", {
                content: "Login Successful!",
                color: "green",
              });
              setTimeout(() => {
                this.$router.push("Dashboard");
              }, 1000);
            }
          } else {
            this.loading1 = false;
            this.Reactivatedailog = true;
          }
        }, 1000);
      } catch (err) {
        console.log("errorssss get current", err);
        this.$store.commit("snackbar/showMessage", {
          content: err.errors[0].message,
          color: "red",
        });
        window.location.reload();
      }
    },
  },
};
</script>

<style>
</style>