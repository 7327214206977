<template>
  <v-dialog v-model="paysuccess" fullscreen flat>
    <v-card class="ma-5">
      <v-card-text align="center">
        <v-btn @click="gotoDashBoard" class="mt-5 gradient-bg white--text"
          >Go to Dashboard</v-btn
        ></v-card-text
      >
      <v-card-text>
        <v-card-text align="center"
          ><strong>Page will Redirect in {{ countDown }}</strong></v-card-text
        >
        <v-img height="50%" src="@/assets/paysuccess.png"></v-img
      ></v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: { paysuccess: Boolean },
  data: () => ({
    height: 0,
    countDown: 10,
  }),
  watch: {
    paysuccess(val) {
      if (val == true) {
        this.callinterval();
        this.countDownTimer();
      } else {
        clearInterval(this.myVar);
      }
    },
  },

  methods: {
    countDownTimer() {
      if (this.countDown > 0) {
        setTimeout(() => {
          this.countDown -= 1;
          this.countDownTimer();
        }, 1000);
      }
    },
    callinterval() {
      this.myVar = setInterval(
        () => {
          this.gotoDashBoard();
        },
        10000,
        this
      );
    },
    gotoDashBoard() {
      if (this.$route.name == "Transactions") {
        this.$router.push("Dashboard");
      } else {
        location.reload();
      }
    },
    callheight() {
      this.height = window.innerHeight - 100;
    },
  },
};
</script>

<style>
</style>