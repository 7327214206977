<template>
  <div>
    <v-dialog v-model="Reportissue" persistent width="390px">
      <v-card class="overflow-hidden">
        <v-toolbar dense class="gradient-bg elevation-0"> </v-toolbar>
        <v-card-text
          align="center"
          style="font-weight: bold; text-align: justify"
          > <div class="mt-5">Would you like to report an issue?</div>
        </v-card-text>
        <div class="d-flex justify-center mb-3">
          <v-card-actions class="mt-6">
            <v-btn
              color="primary"
              class="white--text"
              @click="close_dialog()"
              small
              >cancel</v-btn
            >
            <v-btn
              color="primary"
              class="white--text"
              @click="htmlToImageMethod()"
              small
              >Send</v-btn
            >
          </v-card-actions>
        </div>
      </v-card>
    </v-dialog>
    <div v-if="Reportissue == true" ref="home">
      <v-main>
        <router-view />
      </v-main>
    </div>
    <OverlayO :overlayO="overlayO" />
  </div>
</template>
  <script>
import * as htmlToImage from "html-to-image";
import OverlayO from "@/components/OverlayO.vue";
export default {
  components: {
    OverlayO,
  },
  props: {
    Reportissue: Boolean,
    items: Array,
  },
  data: () => ({
    selectedFilesFromDrop: [],
    overlayO: false,
  }),
  mounted() {
    this.updateNavigationValueMethod();
    console.warn(
      "Check Current IDX",
      this.$store.getters.get_current_route.current_idx
    );
    if (
      this.$store.getters.get_current_route.current_idx != null &&
      this.$store.getters.get_current_route.current_idx != undefined
    ) {
      this.items[this.$store.getters.get_current_route.current_idx].color =
        "primary";
      this.current_idx = this.$store.getters.get_current_route.current_idx;
      if (
        this.items[this.$store.getters.get_current_route.current_idx]
          .has_child_routes == true
      ) {
        this.items[this.$store.getters.get_current_route.current_idx].items[
          this.$store.getters.get_current_route.current_sub_idx
        ].color = "light_primary";
      }
    } else {
      this.$store.commit("SET_CURRENT_ROUTE", 0);
      this.$router.push("Dashboard");
      this.items[0].color = "primary";
      this.current_idx = 0;
    }
  },
  methods: {
    close_dialog() {
      this.$emit("close_report_dialog");
    },
    updateNavigationValueMethod() {
      let previous_route = this.$store.getters.get_previous_route;
      if (previous_route && previous_route != "") {
        switch (previous_route) {
          case "UserChannels":
            this.selectedItem = 0;
            break;
          case "SystemChannels":
            this.selectedItem = 1;
            break;
          case "RaydeoUsers":
            console.log("check coming inside raydeo users");
            this.selectedItem = 2;
            break;
          case "BMSUsers":
            this.selectedItem = 3;
            break;
          case "Settings":
            this.selectedItem = 4;
            break;
        }
      }

      this.$forceUpdate();
    },
    htmlToImageMethod() {
      this.overlayO = true;
      this.$forceUpdate();
      this.selectedFilesFromDrop = [];
      var self = this;
      setTimeout(() => {
        htmlToImage.toBlob(this.$refs.home).then(function (blob) {
          blob.name = `ScreenShot${new Date().getTime()}.png`;
          self.selectedFilesFromDrop.push(blob);
          let object = {
            selectedFilesFromDrop: self.selectedFilesFromDrop,
            routeName: self.$route.name,
          };
          self.$store.commit("SET_ISSUE_DETAILS", object);
          self.$store.commit("SET_PREVIOUS_ROUTE", self.$route.name);
          self.$router.push("ReportIssue");
        });
        self.overlayO = false;
      }, 2000);
      // console.log( this.selectedFilesFromDrop);
    },
    // dialogCreateIssueEmit() {
    //   this.dialogCreateIssue = false;
    //   this.selectedFilesFromDrop = [];
    // },
  },
};
</script>