<template>
  <div>
    <v-img src="@/assets/banner-bg.jpg">
      <v-card-text align="right">
        <v-card
          :style="step != 5 ? 'margin-top: 200px' : 'margin-top: 100px'"
          elevation="2"
          width="450px"
        >
          <v-card-text align="center"
            ><h3>
              {{
                step != 5
                  ? "Enter your Organization Details"
                  : "Review Organization Details"
              }}
            </h3>
          </v-card-text>
          <v-window v-model="step">
            <v-window-item :value="1">
              <v-form ref="name_form">
                <v-card-text>
                  <v-text-field
                    label="Enter Organization Name *"
                    v-model="org_name"
                    dense
                    rounded
                    :rules="org_name_rules"
                    outlined
                  ></v-text-field>
                  <span class="text-caption grey--text text--darken-1">
                    Enter Complete Organization Name *
                  </span>
                </v-card-text>
              </v-form>
            </v-window-item>
            <v-window-item :value="2">
              <v-form ref="address_form">
                <v-card-text>
                  <v-textarea
                    rows="1"
                    auto-grow
                    dense
                    rounded
                    outlined
                    v-model="org_address"
                    :rules="address_rules"
                    label="Enter Organization Address *"
                  ></v-textarea>
                  <span class="text-caption grey--text text--darken-1">
                    Enter Complete Organization Address *
                  </span>
                </v-card-text>
              </v-form>
            </v-window-item>
            <v-window-item :value="3">
              <v-form ref="gst_form">
                <v-card-text>
                  <v-text-field
                    rounded
                    label="Enter GST Number"
                    v-model="gst_number"
                    dense
                    :rules="gst_rules"
                    outlined
                  ></v-text-field>
                  <span class="text-caption grey--text text--darken-1">
                    Enter Organization GST Number (This is not a mandatory
                    field)
                  </span>
                </v-card-text>
                <v-card-text v-show="gst_number.length != 0">
                  <v-select
                    label="Select State *"
                    v-model="state"
                    dense
                    :rules="state_rules"
                    :items="States"
                    item-text="name"
                    item-value="name"
                    outlined
                  ></v-select>
                  <span class="text-caption grey--text text--darken-1">
                    Select the State of the Organization (This is a mandatory
                    field)
                  </span>
                </v-card-text>
              </v-form>
            </v-window-item>
            <v-window-item :value="4">
              <v-form ref="referral_form">
                <v-card-text>
                  <v-text-field
                    label="Enter Referral Code ( if any )"
                    v-model="referral_code"
                    rounded
                    dense
                    :rules="referral_rules"
                    outlined
                  ></v-text-field>
                  <span class="text-caption grey--text text--darken-1">
                    Enter Referral Code *if any* (This is not a mandatory field)
                  </span>
                </v-card-text>
              </v-form>
            </v-window-item>
            <v-window-item :value="5">
              <v-form ref="form">
                <v-card-text align="center">
                  <v-text-field
                    label="Organization Name"
                    v-model="org_name"
                    dense
                    :rules="org_name_rules"
                    rounded
                    readonly
                    filled
                    outlined
                  ></v-text-field>
                  <v-textarea
                    rows="1"
                    rounded
                    auto-grow
                    dense
                    readonly
                    filled
                    outlined
                    v-model="org_address"
                    :rules="address_rules"
                    label="Organization Address"
                  ></v-textarea>
                  <v-text-field
                    label="GST Number"
                    v-model="gst_number"
                    rounded
                    dense
                    readonly
                    filled
                    :rules="gst_rules"
                    outlined
                  ></v-text-field>
                  <v-text-field
                    label="Referral Code"
                    v-model="referral_code"
                    dense
                    :rules="referral_rules"
                    readonly
                    rounded
                    filled
                    outlined
                  ></v-text-field>
                </v-card-text>
              </v-form>
            </v-window-item>
          </v-window>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn v-show="step != 1" text @click="step--"> Back </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              :loading="loading"
              @click="validate_enroll_org()"
              class="gradient-bg white--text"
            >
              <span v-text="step != 5 ? 'Next' : 'Enroll Organization'"></span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-card-text>
    </v-img>
    <SnackBar :SnackBarComponent="SnackBarComponent" />
  </div>
</template>

<script>
import { Country } from "country-state-city";
import * as usertz from "user-timezone";
import { GetCurrentUserDetailsnew } from "@/mixins/getCurrentUserDetails.js";
import SnackBar from "@/components/SnackBar.vue";
import { API, graphqlOperation } from "aws-amplify";
import { CreateOrganisations } from "@/graphql/mutations.js";
import States from "@/mixins/States.json";
import { Auth } from "aws-amplify";

export default {
  components: {
    SnackBar,
  },
  mixins: [GetCurrentUserDetailsnew],

  data: () => ({
    state: "",
    state_rules: [],
    States: [],
    loading: false,
    SnackBarComponent: {},
    referral_rules: [(v) => !v || v.length == 5 || "Enter valid Referral Code"],
    step: 1,
    gst_number: "",
    org_name: "",
    referral_code: "",
    org_address: "",
    org_name_rules: [
      (v) => v.length != 0 || "Organization Name cannot be empty",
      (v) =>
        v.length >= 3 ||
        "Organization Name must contain more than 3 characters",
      (v) =>
        v.length <= 50 ||
        "Organization Name cannot exceed more than 50 characters",
    ],
    address_rules: [
      (v) => v.length != 0 || "Organization Address cannot be empty",
      (v) =>
        v.length >= 3 ||
        "Organization Address must contain more than 3 characters",
      (v) =>
        v.length <= 80 ||
        "Organization Address cannot exceed more than 80 characters",
    ],
    gst_rules: [(v) => !v || v.length == 15 || "Enter valid GST Number"],
    new_timezone: "",
    CountryName: "",
  }),
  watch: {
    gst_number(val) {
      if (val.length != 0) {
        this.state_rules = [(v) => !!v || "required"];
      } else {
        this.state_rules = [];
      }
    },
  },
  mounted() {
    this.CheckRememberMe();
  },
  created() {
    this.States = States;
    this.geoFindMe();
  },
  methods: {
    geoFindMe() {
      const timezone = usertz.getTimeZone();
      this.new_timezone = timezone.includes("/")
        ? timezone.split("/")[1]
        : timezone;
      this.CountryList = Country.getAllCountries();
      fetch(
        "https://api.geoapify.com/v1/ipinfo?apiKey=7dc7ae8d1cc54ab2ae3753110b2a7726"
      )
        .then((resp) => resp.json())
        .then((userLocationData) => {
          this.CountryName = userLocationData.country.name;
        });
    },
    async enroll_organization() {
      this.loading = true;
      try {
        var inputParams = {
          organisation_country: this.CountryName,
          organisation_city: this.new_timezone,
          referral_code: this.referral_code,
          user_email_id: this.$store.getters.get_useremail,
          organisation_name: this.org_name,
          organisation_address: this.org_address,
          organisation_gst_number: this.gst_number,
          user_contact_number: this.$store.getters.get_org_contact_number,
          user_country_code: this.$store.getters.get_org_country_code,
          user_name: this.$store.getters.get_org_user_name,
          onboarding_type: "PORTAL",
          organisation_state: this.state,
        };
        for (const [key, value] of Object.entries(inputParams)) {
          if (value == "") delete inputParams[key];
        }

        await API.graphql(
          graphqlOperation(CreateOrganisations, {
            input: inputParams,
          })
        ).then((res) => {
        
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: res.data.CreateOrganisations.Message,
          };
          this.GetCurrentUserDetailsnew();
          this.callcaptcha();
          this.redirectUser();
          setTimeout(() => {
            this.$router.push("/");
          }, 1000);
          this.loading = false;
        });
      } catch (err) {
        this.loading = false;
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: err.errors[0].message,
        };
      }
    },
    CheckRememberMe() {
      if (this.$store.getters.get_logout_status == true) {
        this.Login = {
          email: "",
          password: "",
        };
        if (localStorage.getItem("currentuseremail") != null) {
          this.Login = JSON.parse(localStorage.getItem("currentuseremail"));
          this.rememberme = true;
        }
      }
      if (this.$store.getters.get_logout_status == false) {
        if (localStorage.getItem("currentuseremail") != null) {
          this.Login = JSON.parse(localStorage.getItem("currentuseremail"));
          this.rememberme = true;
        }
      }
    },
    redirectUser() {
      const path = "/prznce_get_user_details";
      const v = {
        body: {
          user_email_id: this.Login.email.toLowerCase(),
          command: "getCurrentUserDetails",
        },
      };
      API.post("prznce_get_user_details", path, v).then((response) => {
  
        if (response.errorType == "Error") {
          this.$store.commit("snackbar/showMessage", {
            content: response.errorMessage,
            color: "red",
          });
          this.loading = false;
        }
      });
    },
    callcaptcha() {
      this.isLoading = true;

      const code = this.Code;
      window.confirmationResult
        .confirm(code)
        .then((result) => {
          // User signed in successfully.
          const user = result.user;
        
          if (user) {
            this.createUser();
          }
          // ...
        })
        .catch((error) => {
          console.log("errrrrrrrrrrrrrrrrrrrr", error);
          if (error) {
            this.$store.commit("snackbar/showMessage", {
              content: "Code Entered is incorrect. Try again",
              color: "red",
            });
          }
          this.isLoading = false;

          // User couldn't sign in (bad verification code?)
          // ...
        });
    },
    createUser() {
      Auth.signUp({
        username: this.username.toLowerCase(),
        password: this.Password,
        attributes: {
          email: this.username.toLowerCase(), // optional
        },
      })
        .then((data) => {
          console.log(data);
          this.$store.commit(
            "set_org_country_code",
            this.CountryCode.toString().includes("+") == false
              ? "+" + this.CountryCode
              : this.CountryCode
          );

          this.$router.push("/");
          this.$store.commit("snackbar/showMessage", {
            content: "Signup Successful! Login to proceed.",
            color: "green",
          });
          this.isLoading = false;
        })
        .catch((err) => {
          console.log("errrrrrrrr", err);
          this.$store.commit("snackbar/showMessage", {
            content: err,
            color: "red",
          });
          this.isLoading = false;
        });
    },
    validate_enroll_org() {
      switch (this.step) {
        case 1: {
          if (this.$refs.name_form.validate()) {
            this.step = 2;
          } else {
            this.SnackBarComponent = {
              SnackbarVmodel: true,
              SnackbarColor: "red",
              Top: true,
              SnackbarText: "Enter Valid Input",
            };
          }
          break;
        }
        case 2: {
          if (this.$refs.address_form.validate()) {
            this.step = 3;
          } else {
            this.SnackBarComponent = {
              SnackbarVmodel: true,
              SnackbarColor: "red",
              Top: true,
              SnackbarText: "Enter Valid Input",
            };
          }
          break;
        }
        case 3: {
          if (this.$refs.gst_form.validate()) {
            this.step = 4;
          } else {
            this.SnackBarComponent = {
              SnackbarVmodel: true,
              SnackbarColor: "red",
              Top: true,
              SnackbarText: "Enter Valid Input",
            };
          }
          break;
        }
        case 4: {
          if (this.$refs.referral_form.validate()) {
            this.step = 5;
          } else {
            this.SnackBarComponent = {
              SnackbarVmodel: true,
              SnackbarColor: "red",
              Top: true,
              SnackbarText: "Enter Valid Input",
            };
          }
          break;
        }
        case 5: {
          if (this.$refs.form.validate()) {
            this.enroll_organization();
       
          } else {
            this.SnackBarComponent = {
              SnackbarVmodel: true,
              SnackbarColor: "red",
              Top: true,
              SnackbarText: "Enter Valid Input",
            };
          }
          break;
        }
      }
    },
  },
};
</script>

<style></style>
