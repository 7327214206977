/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const GetCurrentUserDetails = /* GraphQL */ `
  query GetCurrentUserDetails($input: GetCurrentUserDetailsInput) {
    GetCurrentUserDetails(input: $input) {
      UserDetails {
        user_type
        user_email_id
        user_status
        organisation_id
        enable_all_swipes
        enable_first_swipe
        user_contact_number
        user_country_code
        is_display
        user_id
        is_tracking_mandatory
        track_duration
        is_simple_scan_enabled
        __typename
      }
      orgdetails {
        organisation_country
        organisation_city
        referral_code
        organisation_id
        organisation_name
        user_email_id
        organisation_created_on
        organisation_address
        organisation_contact_number
        organisation_box_id
        organisation_country_code
        organisation_workshift {
          Monday {
            end_time
            start_time
            weekly_off
            __typename
          }
          Tuesday {
            end_time
            start_time
            weekly_off
            __typename
          }
          Wednesday {
            end_time
            start_time
            weekly_off
            __typename
          }
          Thursday {
            end_time
            start_time
            weekly_off
            __typename
          }
          Friday {
            end_time
            start_time
            weekly_off
            __typename
          }
          Saturday {
            end_time
            start_time
            weekly_off
            __typename
          }
          Sunday {
            end_time
            start_time
            weekly_off
            __typename
          }
          __typename
        }
        is_start_time_notification
        is_end_time_notification
        whatsapp_upstream_count
        organisation_total_credits
        user_contact_number_verified
        organiastion_total_whtsapp_swipes
        organisation_total_proxy_swipes
        organisation_total_camera_swipes
        organisation_total_datacorrections
        organisation_total_reswipes
        organisation_total_api
        organisation_qr_distance
        organisation_gst_number
        is_whatsapp
        is_reswipe_enabled
        is_beacon_enabled
        is_face_enabled
        is_qr_enabled
        is_approve
        organisation_logo
        forms_enable {
          is_qr
          is_face_scan_where
          is_face_all_over
          is_beacon
          is_face
          is_simple_scan_enabled
          __typename
        }
        is_proof_of_work_enable
        customer_activation
        is_customer_recharge
        is_proxy_enabled
        is_blink_eyes
        is_phone_number_mandatory
        is_tracking_mandatory
        track_duration
        is_simple_scan_enabled
        is_data_correction_enabled
        __typename
      }
      AdminAppLatestVersion {
        IOS {
          version
          urllink
          __typename
        }
        Android {
          version
          urllink
          __typename
        }
        __typename
      }
      s3details {
        access_key
        secret_key
        __typename
      }
      cabinetdetails {
        access_key
        secret_key
        bucket_name
        path
        __typename
      }
      sd
      __typename
    }
  }
`;
export const ListAllUsers = /* GraphQL */ `
  query ListAllUsers($input: ListAllUsers) {
    ListAllUsers(input: $input) {
      ActiveMembers {
        user_name
        user_id
        date_of_birth
        user_full_contact_number
        employee_id
        user_country_code
        user_contact_number
        user_email_id
        is_reswipe_enabled
        is_whatsapp
        device_type
        app_version
        first_swipe
        last_swipe
        total_duration
        last_location
        workshift_id
        workshift_name
        user_created_on
        face_s3_key
        face_id
        card_id
        is_qr_enabled
        is_face_enabled
        is_beacon_enabled
        is_scan_any_where
        location_ids
        location_id
        user_type
        designation
        is_proxy_enabled
        is_tracking_mandatory
        track_duration
        is_location_sharing
        home_location_id
        home_location_name
        joining_date
        __typename
      }
      InActivemembers {
        user_name
        user_id
        user_full_contact_number
        employee_id
        user_country_code
        user_contact_number
        user_email_id
        is_reswipe_enabled
        is_whatsapp
        device_type
        app_version
        first_swipe
        last_swipe
        total_duration
        last_location
        user_deactivated_on
        workshift_id
        workshift_name
        user_created_on
        face_s3_key
        face_id
        card_id
        relieving_date
        is_tracking_mandatory
        track_duration
        is_location_sharing
        home_location_id
        home_location_name
        joining_date
        __typename
      }
      Admins {
        user_email_id
        user_id
        user_type
        first_swipe
        last_swipe
        total_duration
        last_location
        is_tracking_mandatory
        track_duration
        is_location_sharing
        home_location_id
        home_location_name
        joining_date
        __typename
      }
      __typename
    }
  }
`;
export const sourceCreditsList = /* GraphQL */ `
  query SourceCreditsList {
    sourceCreditsList
  }
`;
export const listTransactionByOrganisation = /* GraphQL */ `
  query ListTransactionByOrganisation($input: listTransactionByOrganisation) {
    listTransactionByOrganisation(input: $input) {
      data {
        s3_key
        amount
        pack_name
        transaction_by
        transaction_completed_on
        transaction_on
        transaction_status
        transaction_amount
        __typename
      }
      __typename
    }
  }
`;
export const GetInboxDetails = /* GraphQL */ `
  query GetInboxDetails($input: GetInboxDetails) {
    GetInboxDetails(input: $input) {
      data {
        Message
        swipe_time
        __typename
      }
      __typename
    }
  }
`;
export const LateArrivalsUsersList = /* GraphQL */ `
  query LateArrivalsUsersList($input: LateArrivalsUsersList) {
    LateArrivalsUsersList(input: $input)
  }
`;
export const ListAllHolidaysOfOrganisation = /* GraphQL */ `
  query ListAllHolidaysOfOrganisation($input: ListAllHolidaysOfOrganisation) {
    ListAllHolidaysOfOrganisation(input: $input)
  }
`;
export const ListALLLocations = /* GraphQL */ `
  query ListALLLocations($input: ListALLLocations) {
    ListALLLocations(input: $input) {
      ActiveLocations {
        organisation_name
        location_id
        location_source_type
        location_status
        organisation_id
        location_name
        no_of_source
        geo_lat
        geo_long
        location_created_on
        location_created_by
        location_qr_link
        __typename
      }
      InActiveLocations {
        organisation_name
        location_id
        location_source_type
        location_status
        organisation_id
        location_name
        no_of_source
        geo_lat
        geo_long
        location_created_on
        location_created_by
        __typename
      }
      __typename
    }
  }
`;
export const GetLocationsAccordingToStatus = /* GraphQL */ `
  query GetLocationsAccordingToStatus($input: GetLocationsAccordingToStatus) {
    GetLocationsAccordingToStatus(input: $input) {
      data {
        Items {
          organisation_name
          qr_location_name
          location_id
          location_source_type
          location_status
          organisation_id
          location_name
          no_of_source
          geo_lat
          geo_long
          location_created_on
          location_created_by
          location_qr_link
          is_primary
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const GetSourcesTaggedToLocations = /* GraphQL */ `
  query GetSourcesTaggedToLocations($input: GetSourcesTaggedToLocations) {
    GetSourcesTaggedToLocations(input: $input) {
      data {
        qr_location_name
        location_id
        location_source_type
        location_name
        geo_lat
        geo_long
        location_direction
        qr_id
        location_type
        location_tagged_on
        beacon_mac_address
        beacon_minor
        beacon_major
        __typename
      }
      __typename
    }
  }
`;
export const GetSwipesAtLocations = /* GraphQL */ `
  query GetSwipesAtLocations($input: GetSwipesAtLocations) {
    GetSwipesAtLocations(input: $input) {
      data {
        qr_location_name
        qr_id
        organisation_id
        box_location
        swipe_time_stamp
        organisation_name
        user_lat
        user_long
        time_zone
        location_direction
        source
        user_id
        user_name
        swipe_local_time
        swipe_local_timezone
        __typename
      }
      qr_ids {
        location_name
        qr_id
        __typename
      }
      __typename
    }
  }
`;
export const GetAbsentReport = /* GraphQL */ `
  query GetAbsentReport($input: GetAbsentReport) {
    GetAbsentReport(input: $input)
  }
`;
export const leaveReports = /* GraphQL */ `
  query LeaveReports($input: LeaveReportsInput) {
    leaveReports(input: $input) {
      data {
        user_id
        user_name
        leave_type
        user_full_contact_number
        employee_id
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const GetDayReportDetails = /* GraphQL */ `
  query GetDayReportDetails($input: GetDayReportDetails) {
    GetDayReportDetails(input: $input) {
      data {
        employee_id
        user_name
        user_id
        organisation_id
        first_swipe
        last_swipe
        total_duration
        no_of_swipes
        location_name
        swipeslat {
          box_location
          user_long
          user_lat
          qr_location_name
          qr_direction
          __typename
        }
        is_reswipe_enabled
        is_whatsapp
        user_full_contact_number
        face_s3_key
        face_id
        user_country_code
        user_contact_number
        date_of_birth
        user_email_id
        device_type
        app_version
        last_location
        workshift_id
        workshift_name
        user_created_on
        card_id
        is_qr_enabled
        is_face_enabled
        is_beacon_enabled
        is_scan_any_where
        location_ids
        location_id
        user_type
        designation
        time_zone
        is_tracking_mandatory
        track_duration
        is_location_sharing
        __typename
      }
      __typename
    }
  }
`;
export const GetAllSwipesForParticularEmployee = /* GraphQL */ `
  query GetAllSwipesForParticularEmployee(
    $input: GetAllSwipesForParticularEmployee
  ) {
    GetAllSwipesForParticularEmployee(input: $input) {
      data {
        user_id
        box_location
        user_lat
        user_long
        swipe_time_stamp
        qr_location_name
        source
        location_direction
        qr_direction
        swipe_local_time
        swipe_local_timezone
        is_dummy_swipe
        swipe_message
        __typename
      }
      __typename
    }
  }
`;
export const GetAllSwipesForParticularEmployeeV3 = /* GraphQL */ `
  query GetAllSwipesForParticularEmployeeV3(
    $input: GetAllSwipesForParticularEmployee
  ) {
    GetAllSwipesForParticularEmployeeV3(input: $input) {
      items {
        first_swipe
        last_swipe
        attendence
        dynamo_date
        __typename
      }
      __typename
    }
  }
`;
export const GetSwipesForOrganisations = /* GraphQL */ `
  query GetSwipesForOrganisations($input: GetSwipesForOrganisations) {
    GetSwipesForOrganisations(input: $input) {
      data {
        swipe_id
        qr_location_name
        qr_id
        organisation_id
        box_location
        swipe_time_stamp
        organisation_name
        user_lat
        user_long
        time_zone
        source
        user_id
        user_name
        user_contact_number
        user_country_code
        employee_id
        comment
        swipe_data_corrected_on
        corrected_by
        qr_direction
        location_direction
        unknown_swipes
        card_id
        is_inavlid_swipe
        is_dummy_swipe
        approval_status
        forms {
          forms_id
          swipe_id
          working_type
          working_comments
          working_field_keys
          swipe_time_stamp
          user_id
          organisation_id
          swipe_date
          is_free_text
          form_payload
          form_template {
            data_type
            display_label
            display_weightage
            input_array_value {
              id
              type
              __typename
            }
            is_displayed
            is_readonly
            is_required
            key
            section
            value
            value_label
            __typename
          }
          __typename
        }
        approver_email_id
        acted_on
        swipe_local_time
        swipe_local_timezone
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAdminLatestVersion = /* GraphQL */ `
  query GetAdminLatestVersion {
    getAdminLatestVersion {
      PrezenceAdminappLatestVersion {
        Version {
          Android {
            version
            urllink
            __typename
          }
          IOS {
            version
            urllink
            __typename
          }
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const holidayFilterByYear = /* GraphQL */ `
  query HolidayFilterByYear($input: holidayFilterByYear) {
    holidayFilterByYear(input: $input)
  }
`;
export const SwipesOfEmployee = /* GraphQL */ `
  query SwipesOfEmployee($input: SwipesOfEmployee) {
    SwipesOfEmployee(input: $input)
  }
`;
export const MonthlyReportDetails = /* GraphQL */ `
  query MonthlyReportDetails($input: MonthlyReportDetails) {
    MonthlyReportDetails(input: $input)
  }
`;
export const getWorkOnOffDays = /* GraphQL */ `
  query GetWorkOnOffDays($input: getWorkOnOffDays) {
    getWorkOnOffDays(input: $input)
  }
`;
export const PrznceDashBoard = /* GraphQL */ `
  query PrznceDashBoard($input: PrznceDashBoard) {
    PrznceDashBoard(input: $input)
  }
`;
export const GetTimeZone = /* GraphQL */ `
  query GetTimeZone {
    GetTimeZone
  }
`;
export const validateQr = /* GraphQL */ `
  query ValidateQr($input: validateQr) {
    validateQr(input: $input) {
      message
      location_name
      qr_direction
      qr_location_name
      location_type
      __typename
    }
  }
`;
export const AuditLogs = /* GraphQL */ `
  query AuditLogs($input: AuditLogs) {
    AuditLogs(input: $input)
  }
`;
export const YearlyReport = /* GraphQL */ `
  query YearlyReport($input: YearlyReport) {
    YearlyReport(input: $input)
  }
`;
export const GetAllSwipesForParticularEmployeePrznceAdminApp = /* GraphQL */ `
  query GetAllSwipesForParticularEmployeePrznceAdminApp(
    $input: GetAllSwipesForParticularEmployeePrznceAdminApp
  ) {
    GetAllSwipesForParticularEmployeePrznceAdminApp(input: $input) {
      data {
        user_id
        box_location
        user_lat
        user_long
        swipe_time_stamp
        qr_location_name
        source
        location_direction
        qr_direction
        swipe_local_time
        swipe_local_timezone
        is_dummy_swipe
        swipe_message
        __typename
      }
      __typename
    }
  }
`;
export const monthlyScansReports = /* GraphQL */ `
  query MonthlyScansReports($input: monthlyScansReports) {
    monthlyScansReports(input: $input)
  }
`;
export const GetParticularMembersScans = /* GraphQL */ `
  query GetParticularMembersScans($input: GetParticularMembersScans) {
    GetParticularMembersScans(input: $input)
  }
`;
export const GetAbsentReportMobile = /* GraphQL */ `
  query GetAbsentReportMobile($input: GetAbsentReportMobile) {
    GetAbsentReportMobile(input: $input) {
      data {
        user_full_contact_number
        user_name
        user_email_id
        employee_id
        organisation_id
        user_id
        face_s3_key
        face_id
        __typename
      }
      __typename
    }
  }
`;
export const listBoxOfOrganisation = /* GraphQL */ `
  query ListBoxOfOrganisation($input: listBoxOfOrganisationInput) {
    listBoxOfOrganisation(input: $input) {
      response {
        box_created_on
        box_id
        location_id
        box_claimed_by
        box_direction
        organisation_id
        box_status
        box_name
        box_unique_id
        message
        __typename
      }
      __typename
    }
  }
`;
export const listWorkshiftForOrganisation = /* GraphQL */ `
  query ListWorkshiftForOrganisation(
    $input: listWorkshiftForOrganisationInput
  ) {
    listWorkshiftForOrganisation(input: $input)
  }
`;
export const GetBoxDetails = /* GraphQL */ `
  query GetBoxDetails($input: GetBoxDetailsInput) {
    GetBoxDetails(input: $input) {
      box_created_on
      box_id
      location_id
      box_claimed_by
      box_direction
      organisation_id
      box_status
      box_name
      box_unique_id
      message
      __typename
    }
  }
`;
export const get_beacon_details_check = /* GraphQL */ `
  query Get_beacon_details_check($input: GetBeaconDetailsCheckInput) {
    get_beacon_details_check(input: $input) {
      Status
      beacon {
        beacon_id
        beacon_mac_address
        beacon_created_on
        beacon_email_created_by
        beacon_major
        beacon_minor
        beacon_name
        beacon_status
        is_beacon_calimed
        is_shipped
        location_id
        organisation_id
        __typename
      }
      __typename
    }
  }
`;
export const list_all_beacon_for_organisation = /* GraphQL */ `
  query List_all_beacon_for_organisation(
    $input: ListAllBeaconForOrganisationInput
  ) {
    list_all_beacon_for_organisation(input: $input) {
      activeBeacon {
        beacon_id
        beacon_mac_address
        beacon_created_on
        beacon_email_created_by
        beacon_major
        beacon_minor
        beacon_name
        beacon_status
        is_beacon_calimed
        is_shipped
        location_id
        organisation_id
        __typename
      }
      inactiveBeacon {
        beacon_id
        beacon_mac_address
        beacon_created_on
        beacon_email_created_by
        beacon_major
        beacon_minor
        beacon_name
        beacon_status
        is_beacon_calimed
        is_shipped
        location_id
        organisation_id
        __typename
      }
      allBeacon {
        beacon_id
        beacon_mac_address
        beacon_created_on
        beacon_email_created_by
        beacon_major
        beacon_minor
        beacon_name
        beacon_status
        is_beacon_calimed
        is_shipped
        location_id
        organisation_id
        __typename
      }
      __typename
    }
  }
`;
export const list_visitors = /* GraphQL */ `
  query List_visitors($input: ListVisitorsInput) {
    list_visitors(input: $input) {
      Status
      visitors {
        user_id
        user_created_on
        organisation_id
        user_name
        user_status
        user_contact_number
        user_country_code
        profile_s3_key
        location_name
        last_visited_on
        __typename
      }
      __typename
    }
  }
`;
export const list_or_particular_visitors_logs = /* GraphQL */ `
  query List_or_particular_visitors_logs(
    $input: ListOrParticularVisitorslogsInput
  ) {
    list_or_particular_visitors_logs(input: $input) {
      Status
      logs {
        visitor_id
        is_approve
        location_id
        no_of_visitors
        purpose_of_visit
        timestamp
        whom_to_meet
        location_name
        user_name
        user_contact_number
        user_country_code
        profile_s3_key
        approved_by
        __typename
      }
      Message
      __typename
    }
  }
`;
export const visitor_swipe_information = /* GraphQL */ `
  query Visitor_swipe_information($input: VisitorSwipeInformationInput) {
    visitor_swipe_information(input: $input) {
      Status
      visitor {
        visitor_id
        is_approve
        location_id
        no_of_visitors
        purpose_of_visit
        timestamp
        whom_to_meet
        location_name
        user_name
        user_contact_number
        user_country_code
        profile_s3_key
        approved_by
        __typename
      }
      __typename
    }
  }
`;
export const monthly_report_detailsv2 = /* GraphQL */ `
  query Monthly_report_detailsv2($input: MonthlyReportDetailsInputv2) {
    monthly_report_detailsv2(input: $input)
  }
`;
export const monthly_scans_reports_v2 = /* GraphQL */ `
  query Monthly_scans_reports_v2($input: MonthlyScansReportsInputv2) {
    monthly_scans_reports_v2(input: $input)
  }
`;
export const location_qr_print = /* GraphQL */ `
  query Location_qr_print($input: LocationQrPrintInput) {
    location_qr_print(input: $input)
  }
`;
export const list_all_members_qr_print = /* GraphQL */ `
  query List_all_members_qr_print($input: ListAllMembersQrPrint) {
    list_all_members_qr_print(input: $input)
  }
`;
export const get_list_of_reports = /* GraphQL */ `
  query Get_list_of_reports($input: GetListOfReportsInput) {
    get_list_of_reports(input: $input)
  }
`;
export const list_trans_details_of_user_or_org = /* GraphQL */ `
  query List_trans_details_of_user_or_org(
    $input: ListTransDetailsOfUserOrOrgInput
  ) {
    list_trans_details_of_user_or_org(input: $input) {
      Status
      Message
      data {
        user_name
        transaction_created_on
        approve_status
        user_id
        ammount_requested
        requested_hours
        dynamo_month
        dynamo_date
        transaction_id
        approved_amount
        ammount_type
        approved_on
        pending_on
        images_keys
        __typename
      }
      __typename
    }
  }
`;
export const GetLocationsAccordingToStatusVisitor = /* GraphQL */ `
  query GetLocationsAccordingToStatusVisitor(
    $input: GetLocationsAccordingToStatus
  ) {
    GetLocationsAccordingToStatusVisitor(input: $input) {
      data {
        Items {
          organisation_name
          qr_location_name
          location_id
          location_source_type
          location_status
          organisation_id
          location_name
          no_of_source
          geo_lat
          geo_long
          location_created_on
          location_created_by
          location_qr_link
          is_primary
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const PrznceDashBoardPhone = /* GraphQL */ `
  query PrznceDashBoardPhone($input: PrznceDashBoard) {
    PrznceDashBoardPhone(input: $input) {
      result {
        totalUsers
        ActiveUsers
        InActiveUsers
        total_credits
        total_swipes
        consumed_credits
        total_locations
        total_active_locations
        total_inactive_locations
        total_qr_count
        __typename
      }
      __typename
    }
  }
`;
export const getLocationsForOrg = /* GraphQL */ `
  query GetLocationsForOrg($input: GetLocationsForOrgInput) {
    getLocationsForOrg(input: $input) {
      data {
        loc_id
        loc_name
        __typename
      }
      __typename
    }
  }
`;
export const members_days_monthly_report = /* GraphQL */ `
  query Members_days_monthly_report($input: MembersDaysMonthlyReportInput) {
    members_days_monthly_report(input: $input) {
      daily_per_abs
      total_present
      total_absent
      total_leaves
      total_holidays
      today_worked_duration
      total_monthly_worked_duration
      all_dates_details {
        date
        attendence
        total_duration
        occasion_type
        __typename
      }
      working_timeings
      is_early_salary_enabled
      swipes_list {
        Box_ID
        timeStamp
        userLat
        userLong
        timeZone
        offsetseconds
        abbreviation
        Resource_ID
        Org_ID
        Card_ID
        Name
        Box_Location
        isDynamic
        OrgName
        Direction
        source
        proxyby
        forms {
          forms_id
          swipe_id
          working_type
          working_comments
          working_field_keys
          swipe_time_stamp
          user_id
          organisation_id
          swipe_date
          is_free_text
          form_payload
          form_template {
            data_type
            display_label
            display_weightage
            input_array_value {
              id
              type
              __typename
            }
            is_displayed
            is_readonly
            is_required
            key
            section
            value
            value_label
            __typename
          }
          __typename
        }
        swipe_id
        forms_filled
        is_forms
        swipe_local_time
        swipe_local_timezone
        __typename
      }
      __typename
    }
  }
`;
export const proof_of_work_report = /* GraphQL */ `
  query Proof_of_work_report($input: ProofOfWorkReportInput) {
    proof_of_work_report(input: $input)
  }
`;
export const get_swipes_proxy_organisations = /* GraphQL */ `
  query Get_swipes_proxy_organisations(
    $input: GetSwipesProxyOrganisationsInput
  ) {
    get_swipes_proxy_organisations(input: $input)
  }
`;
export const get_all_swipes_for_employee_in_member_app = /* GraphQL */ `
  query Get_all_swipes_for_employee_in_member_app(
    $input: GetAllSwipesForEmployeeInMemberAppInput
  ) {
    get_all_swipes_for_employee_in_member_app(input: $input) {
      items {
        Box_ID
        timeStamp
        userLat
        userLong
        timeZone
        offsetseconds
        abbreviation
        Resource_ID
        Org_ID
        Card_ID
        Name
        Box_Location
        isDynamic
        OrgName
        Direction
        source
        proxyby
        forms {
          forms_id
          swipe_id
          working_type
          working_comments
          working_field_keys
          swipe_time_stamp
          user_id
          organisation_id
          swipe_date
          is_free_text
          form_payload
          form_template {
            data_type
            display_label
            display_weightage
            input_array_value {
              id
              type
              __typename
            }
            is_displayed
            is_readonly
            is_required
            key
            section
            value
            value_label
            __typename
          }
          __typename
        }
        swipe_id
        forms_filled
        is_forms
        expires_timestamp
        expires_timesatmp
        swipe_local_time
        swipe_local_timezone
        __typename
      }
      next_token
      __typename
    }
  }
`;
export const master_leave_settings = /* GraphQL */ `
  query Master_leave_settings($input: MasterLeaveSettingsInput) {
    master_leave_settings(input: $input)
  }
`;
export const list_forms = /* GraphQL */ `
  query List_forms($input: ListFormsInput) {
    list_forms(input: $input)
  }
`;
export const country_credits_list_edit = /* GraphQL */ `
  query Country_credits_list_edit($input: CountryCreditsListEditInput) {
    country_credits_list_edit(input: $input)
  }
`;
export const list_all_users_v2 = /* GraphQL */ `
  query List_all_users_v2($input: listUsersInput) {
    list_all_users_v2(input: $input) {
      details {
        user_name
        user_id
        date_of_birth
        user_full_contact_number
        employee_id
        user_country_code
        user_contact_number
        user_email_id
        is_reswipe_enabled
        is_whatsapp
        device_type
        app_version
        first_swipe
        last_swipe
        total_duration
        last_location
        workshift_id
        workshift_name
        user_created_on
        face_s3_key
        face_id
        card_id
        is_qr_enabled
        is_face_enabled
        is_beacon_enabled
        is_scan_any_where
        location_ids
        location_id
        organisation_id
        user_type
        designation
        employee_type
        user_deactivated_on
        relieving_date
        is_proxy_enabled
        home_location_id
        home_location_name
        is_tracking_mandatory
        track_duration
        is_simple_scan_enabled
        joining_date
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const list_leave_approvals = /* GraphQL */ `
  query List_leave_approvals($input: ListLeaveApprovalsInput) {
    list_leave_approvals(input: $input) {
      Status
      data {
        leave_id
        leave_applied_on
        leave_from_date
        leave_to_date
        leave_type
        total_days_leave
        leave_status
        user_id
        user_name
        leave_year
        leave_month
        organisation_id
        leave_comments
        approved_on
        approved_by
        approvals_comment
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const list_all_approvals = /* GraphQL */ `
  query List_all_approvals($input: ListAllApprovalsInput) {
    list_all_approvals(input: $input) {
      items {
        approval_id
        organisation_id
        approved_on
        created_on
        approval_type
        approval_status
        user_id
        user_name
        payload {
          leave_id
          leave_applied_on
          leave_from_date
          leave_to_date
          leave_type
          total_days_leave
          leave_status
          user_id
          user_name
          leave_year
          leave_month
          leave_comments
          location_name
          datacorrection_time
          s3_url
          remarks
          __typename
        }
        approvals_comment
        face_s3_key
        approval_user_email_id
        __typename
      }
      __typename
    }
  }
`;
export const list_face_approvals = /* GraphQL */ `
  query List_face_approvals($input: ListAllApprovalsInput) {
    list_face_approvals(input: $input) {
      items {
        approval_id
        organisation_id
        approved_on
        created_on
        approval_type
        approval_status
        user_id
        user_name
        payload {
          leave_id
          leave_applied_on
          leave_from_date
          leave_to_date
          leave_type
          total_days_leave
          leave_status
          user_id
          user_name
          leave_year
          leave_month
          leave_comments
          location_name
          datacorrection_time
          s3_url
          remarks
          __typename
        }
        approvals_comment
        face_s3_key
        approval_user_email_id
        __typename
      }
      __typename
    }
  }
`;
export const list_swipes_for_faces = /* GraphQL */ `
  query List_swipes_for_faces($input: ListSwipesForFacesInput) {
    list_swipes_for_faces(input: $input)
  }
`;
export const list_designations = /* GraphQL */ `
  query List_designations($input: ListDesignationsInput) {
    list_designations(input: $input)
  }
`;
export const payments_plans = /* GraphQL */ `
  query Payments_plans($input: PaymentsPlansInput) {
    payments_plans(input: $input)
  }
`;
export const list_groups = /* GraphQL */ `
  query List_groups($input: ListGroupsInput) {
    list_groups(input: $input)
  }
`;
export const list_forms_for_tags = /* GraphQL */ `
  query List_forms_for_tags($input: ListFormsForTagsInput) {
    list_forms_for_tags(input: $input)
  }
`;
export const list_employee_type = /* GraphQL */ `
  query List_employee_type($input: ListEmployeeTypeInput) {
    list_employee_type(input: $input)
  }
`;
export const get_weekly_report_v2 = /* GraphQL */ `
  query Get_weekly_report_v2($input: GetWeeklyReportV2Input) {
    get_weekly_report_v2(input: $input)
  }
`;
export const list_all_users_groups = /* GraphQL */ `
  query List_all_users_groups($input: ListAllUsersGroupsInput) {
    list_all_users_groups(input: $input)
  }
`;
export const get_user_details = /* GraphQL */ `
  query Get_user_details($input: GetUserDeatilsInput) {
    get_user_details(input: $input) {
      items {
        user_name
        user_id
        date_of_birth
        user_full_contact_number
        employee_id
        user_country_code
        user_contact_number
        user_email_id
        is_reswipe_enabled
        is_whatsapp
        device_type
        app_version
        first_swipe
        last_swipe
        total_duration
        last_location
        workshift_id
        workshift_name
        user_created_on
        face_s3_key
        face_id
        card_id
        is_qr_enabled
        is_face_enabled
        is_beacon_enabled
        is_scan_any_where
        location_ids
        location_id
        user_type
        designation
        is_proxy_enabled
        __typename
      }
      __typename
    }
  }
`;
export const app_install_users_list = /* GraphQL */ `
  query App_install_users_list($input: AppInstallUsersListInput) {
    app_install_users_list(input: $input)
  }
`;
export const list_users_master = /* GraphQL */ `
  query List_users_master($input: ListUsersMasterInput) {
    list_users_master(input: $input)
  }
`;
export const list_forms_library = /* GraphQL */ `
  query List_forms_library($input: ListFormsInput) {
    list_forms_library(input: $input)
  }
`;
export const GetLiveTrackData = /* GraphQL */ `
  query GetLiveTrackData($input: GetLiveTrackDataInput) {
    GetLiveTrackData(input: $input) {
      Status
      data {
        organisation_id
        user_id
        user_lat
        user_long
        track_date
        track_time
        __typename
      }
      next_token
      __typename
    }
  }
`;
export const getAllSwipesForParticularUser = /* GraphQL */ `
  query GetAllSwipesForParticularUser(
    $input: getAllSwipesForParticularUserInput
  ) {
    getAllSwipesForParticularUser(input: $input) {
      Status
      data {
        first_swipe
        last_swipe
        first_swipe_timezone
        last_swipe_timezone
        attendence
        dynamo_date
        swipe_data {
          Box_ID
          timeStamp
          userLat
          userLong
          timeZone
          offsetseconds
          abbreviation
          Resource_ID
          Org_ID
          Card_ID
          Name
          Box_Location
          isDynamic
          OrgName
          Direction
          source
          proxyby
          forms {
            forms_id
            swipe_id
            working_type
            working_comments
            working_field_keys
            swipe_time_stamp
            user_id
            organisation_id
            swipe_date
            is_free_text
            form_payload
            form_template {
              data_type
              display_label
              display_weightage
              input_array_value {
                id
                type
                __typename
              }
              is_displayed
              is_readonly
              is_required
              key
              section
              value
              value_label
              __typename
            }
            __typename
          }
          swipe_id
          forms_filled
          is_forms
          expires_timestamp
          expires_timesatmp
          is_form_enabled
          swipe_local_time
          swipe_local_timezone
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const getReports = /* GraphQL */ `
  query GetReports($input: getReportsInput) {
    getReports(input: $input)
  }
`;
export const getLivetrackingReport = /* GraphQL */ `
  query GetLivetrackingReport($input: getLiveTrackingReportsInput) {
    getLivetrackingReport(input: $input)
  }
`;
