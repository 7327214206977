<template>
  <div>
    <v-dialog v-model="emailotp" persistent width="390px">
      <v-card class="overflow-hidden">
        <v-toolbar dense class="gradient-bg elevation-0 white--text">
          <v-toolbar-title>Email Verification and Create</v-toolbar-title
          ><v-spacer />
          <!-- <v-icon @click="close_dialog()" class="white--text mr-n3"
            >mdi-close</v-icon
          > -->
        </v-toolbar>
        <v-card-text class="mt-4">
          <v-form ref="form">
            <label class="ml-7 font-weight-medium">Enter Email OTP</label>
            <v-otp-input
              v-model="emailCode"
              :rules="otp_rules"
              class="mr-6 ml-6 mt-4"
            />
          </v-form>
          <div class="ml-8 mt-4 font-weight-medium">
            Resend OTP in ({{ timer }}s)
            <v-icon
              v-if="!timerRunning"
              color="#EF6C00"
              class="mb-2 ml-2"
              @click="restartTimer"
              >mdi-refresh</v-icon
            >
          </div>

          <!-- @finish="submitcode()" -->
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            small
            class="gradient-bg white--text mb-2 ml-2"
            :loading="createload"
            @click="check_phone"
            >Create</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
  
  <script>
/* eslint-disable */
import axios from "axios";
import { GetCurrentUserDetails } from "@/graphql/queries.js";
import { API, graphqlOperation } from "aws-amplify";
import { Auth } from "aws-amplify";
export default {
  props: {
    emailotp: Boolean,
    emailitems: Object,
    userDetails: Object,
  },
  data() {
    return {
      otp_rules: [
        (v) => /^\d+$/.test(v) || "OTP must contain only numbers",
        (v) => v.length != 0 || "OTP cannot be empty",
        (v) => v.length == 6 || "Enter Correct OTP",
      ],
      timer: 60,
      timerRunning: false,
      Getorgcurrent: {},
      GetCurrentUserObject2: {},
      OtpUserObject:{},
      emailCode: "",
      createload: false,
    };
  },
  watch: {
    emailotp: {
      handler() {
        // console.log(this.emailitems);
        this.OtpUserObject= this.userDetails;
        // this.userDetails
        this.startTimer();
      },
      immediate: true,
    },
  },
  methods: {
    // close_dialog() {
    //   this.timer = 60;
    //   this.timerRunning = false;
    //   this.$emit("clicked", 0);
    // },
    check_phone() {
      if (this.$refs.form.validate()) {
        this.login_with_otp();
      } else {
        this.$emit("clicked", 1);
      }
    },
    async login_with_otp() {
      // console.log(this.userDetails,'login');
      this.createload = true;
      try {
        await Auth.sendCustomChallengeAnswer( this.OtpUserObject, this.emailCode);
        await Auth.currentAuthenticatedUser()
          .then((user) => {
            this.$store.commit("SET_AUTH", true);
            this.$store.commit("SET_USEREMAIL", this.emailitems.email);
            this.$store.commit("SET_CURRENT_ROUTE", 0);
            this.createload = false;
            this.createorg();
          })
          .catch((err) => {
            this.createload = false;
            console.log(err);
            // this.$emit("errorMsg", err.errors[0].message);
            this.$emit("errorMsg", "Invalid OTP");
          });
      } catch (error) {
        this.createload = false;
        console.log(error);
        console.log(error.message);

        if (error.message == "Incorrect username or password.") {
          this.$emit("errorMsg", "Invalid OTP");
        } else if (error.message == "Challenge response cannot be empty") {
          this.$emit("errorMsg", "Please Enter OTP Manually");
        } else if (error.message == "Invalid session for the user.") {
          this.$emit(
            "errorMsg",
            "Multiple Attempts, Please Resend OTP and Login again"
          );
        }
        else{
          this.$emit("errorMsg", error.message)
        }
        // this.$emit("errorMsg", error.message);
      }
    },
    startTimer() {
      this.timerRunning = true;
      this.timer = 60;
      this.countdown();
    },

    restartTimer() {
      this.generate_otp();
      this.timer = 60;
      this.countdown();
      this.timerRunning = true;
    },

    countdown() {
      if (this.timer > 0) {
        setTimeout(() => {
          this.timer--;
          this.countdown();
        }, 1000);
      } else {
        this.timerRunning = false;
      }
    },
    async generate_otp() {
      this.OtpUserObject={};
      try {
        const user = await Auth.signIn(this.emailitems.email)
          .then((user) => {
            this.OtpUserObject = user;
            // console.log( this.userDetails,'newotp');
            this.$emit("clicked", 3);
            this.focusOnOTPField();
          })
          .catch((err) => {
            this.$emit("errorMsg", err.errors[0].message);
          });
      } catch (error) {
        this.$emit("errorMsg", error.errors[0].message);
      }
    },
    async createorg() {
      // var data = {
      //   organisation_country: this.emailitems.organisation_country,
      //   organisation_city: this.emailitems.organisation_city,
      //   user_email_id: this.emailitems.email,
      //   organisation_name: this.emailitems.organisation_name,
      //   organisation_address: this.emailitems.organisation_address,
      //   user_contact_number: this.emailitems.user_contact_number,
      //   user_country_code: this.emailitems.user_country_code,
      //   user_name: this.emailitems.user_name,
      //   onboarding_type: "PORTAL",
      //   organisation_state: this.state,
      //   command: "createOrganisation",
      //   geo_lat: this.emailitems.geo_lat,
      //   geo_long: this.emailitems.geo_long,
      // };
      this.createload = true;
      axios({
        method: "POST",
        url: "https://liql60b1h9.execute-api.ap-south-1.amazonaws.com/prezence/email_phone_avaliabilty_check_v2 ",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          organisation_country: this.emailitems.organisation_country,
          organisation_city: this.emailitems.organisation_city,
          user_email_id: this.emailitems.email,
          organisation_name: this.emailitems.organisation_name.trim(),
          organisation_address: this.emailitems.organisation_address,
          user_contact_number: this.emailitems.user_contact_number,
          user_country_code: this.emailitems.user_country_code,
          user_name: this.emailitems.user_name.trim(),
          onboarding_type: "PORTAL",
          organisation_state: this.state,
          command: "createOrganisation",
          geo_lat: this.emailitems.geo_lat,
          geo_long: this.emailitems.geo_long,
        },
      })
        .then((res) => {
          // console.log(res);
          if (res.data.errorType == "Error") {
            console.log(res, "error");
            this.$emit("errorMsg", res.data.errorMessage);
          } else {
            this.createload = false;
            this.GetCurrentUserDetailslogin();
            setTimeout(() => {
              // console.log(res, "succes");
              this.$store.commit("snackbar/showMessage", {
                content: "Login Successful!",
                color: "green",
              });
              this.$router.push("/Dashboard");
            }, 1000);
          }

          this.createload = false;
        })
        .catch((err) => {
          this.createload = false;
          console.log(err);
          this.$emit("errorMsg", err.errors[0].message);
        });
    },
    async GetCurrentUserDetailslogin() {
      try {
        let result = await API.graphql(
          graphqlOperation(GetCurrentUserDetails, {
            input: {
              user_email_id: this.emailitems.email.toLowerCase(),
            },
          })
        );
        this.Getorgcurrent = result.data.GetCurrentUserDetails.orgdetails;
        this.GetCurrentUserObject2 =
          result.data.GetCurrentUserDetails.UserDetails;

        this.$store.commit("set_org_user_details", this.GetCurrentUserObject2);
        this.$store.commit("set_org_details", this.Getorgcurrent);
      } catch (err) {
        console.log("errorssss get current", err);
        this.$store.commit("snackbar/showMessage", {
          content: err.errors[0].message,
          color: "red",
        });
      }
    },
  },
};
</script>
  
  <style>
</style>