import {
    API,
    graphqlOperation
} from "aws-amplify";
import {
    GetCurrentUserDetails
} from "@/graphql/queries.js";

export const GetCurrentUserDetailsnew = {
    data: () => ({
        GetCurrentUserObject: {},
        GetCurrentOrgObject: {}
    }),
    methods: {
        async GetCurrentUserDetailsnew() {
            try {
                this.MembersItems = [];
                let result = await API.graphql(
                    graphqlOperation(GetCurrentUserDetails, {
                        input: {
                            user_email_id: this.$store.getters.get_useremail,
                        },
                    })
                );
                this.GetCurrentOrgObject = result.data.GetCurrentUserDetails.orgdetails;
                this.GetCurrentUserObject = result.data.GetCurrentUserDetails.UserDetails;
                this.$store.commit('set_org_user_details', this.GetCurrentUserObject)
                this.$store.commit('set_org_details', this.GetCurrentOrgObject)
                var array = result.data.GetCurrentUserDetails.sd;
                this.$store.commit('set_access_details',array)

            } catch (err) {
                console.log("errorssss get current", err);
            }
        },
    }
}