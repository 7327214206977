import Vue from 'vue'
import App from './App.vue'
import router from "./router/index.js";
import vuetify from './plugins/vuetify'
import store from "./store/index.js";
import Amplify from "aws-amplify";
import VueGoogleMap from 'vuejs-google-maps'
import 'vuejs-google-maps/dist/vuejs-google-maps.css'
import * as VueGoogleMaps from "vue2-google-maps";
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import VueQRCodeComponent from 'vue-qr-generator'
import DatetimePicker from 'vuetify-datetime-picker'
import VueClipboard from 'vue-clipboard2'
import SequentialEntrance from "vue-sequential-entrance";
import * as GmapVue from 'gmap-vue';
VueClipboard.config.autoSetContainer = true
Vue.use(VueClipboard)
Vue.use(SequentialEntrance);
Vue.use(DatetimePicker)

Vue.component('qr-code', VueQRCodeComponent)
const Component = () => (
  <div>
    <GooglePlacesAutocomplete
      apiKey="AIzaSyBGx0GVYXax5i6Bs3eCglSrUGaIAvKrgXk"
    />
  </div>
);
Vue.use(VueGoogleMap, {
  load: {
    apiKey: 'AIzaSyBGx0GVYXax5i6Bs3eCglSrUGaIAvKrgXk',
    libraries: ["places"]
  }
})
Vue.use(GmapVue, {
  load: {
    key: 'AIzaSyBGx0GVYXax5i6Bs3eCglSrUGaIAvKrgXk',
    libraries: 'places', 
  },
});

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyBGx0GVYXax5i6Bs3eCglSrUGaIAvKrgXk",
    libraries: "places" // necessary for places input
  }
});

Amplify.configure({
  Auth: {
    userPoolId: "ap-south-1_MFLSQGP6m",
    region: "ap-south-1",
    userPoolWebClientId: "2dg3ufeg95heeahikbkjq4kja7",
  },
  API: {
    endpoints: [{
      name: "prznce_get_user_details",
      endpoint: "https://liql60b1h9.execute-api.ap-south-1.amazonaws.com/prezence"
    },
    {
      name: "prznce_payment",
      endpoint: "https://liql60b1h9.execute-api.ap-south-1.amazonaws.com/prezence"
    },
    {
      name: "prznce_data_correction",
      endpoint: "https://liql60b1h9.execute-api.ap-south-1.amazonaws.com/prezence"
    },
    {
      name: "get_data_correction_list",
      endpoint: "https://liql60b1h9.execute-api.ap-south-1.amazonaws.com"
    },
    {
      name: "Report_issue",
      endpoint: " https://61fvl4pi50.execute-api.ap-southeast-1.amazonaws.com/dev/",
    },
    ],
    "aws_project_region": "ap-south-1",
    "aws_appsync_graphqlEndpoint": "https://ocgbqd4vkbbypouvkje3jtn2vq.appsync-api.ap-south-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-south-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS"
  },

});

Vue.config.productionTip = false
export default Component;

new Vue({
  router,
  vuetify,
  store,
  render: h => h(App)
}).$mount('#app')