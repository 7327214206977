<template>
  <v-app>
    <router-view />
    <Snackbarstore />
  </v-app>
</template>
<script>
import Snackbarstore from "@/components/snackbarstore.vue";

export default {
  components: {
    Snackbarstore,
  },
  data: () => ({}),
};
</script>
<style>
/* .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
} */
.overflow {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 100%;
}
.field_height.v-text-field .v-input_control .v-input_slot {
  min-height: 12px !important;
  display: flex !important;
  align-items: center !important;
}
.field_label_size.v-input .v-label {
  font-size: 12px;
}
.v-select .v-input_control .v-inputslot .v-selectslot .v-select_selections {
  padding: 0 !important;
  min-height: 0 !important;
}
.cursor {
  cursor: pointer;
}

.v-select-list .v-list-item {
  min-height: 0 !important;
}
/* .overflow {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 100%;
} */
.aligncenter {
  justify-content: center;
}
.gradient-bg2 {
  /* .focus-login-input:before,
.blog-pagination .page-link:hover,
.blog-pagination .page-item.active .page-link,
.blog_right_sidebar .tag_cloud_widget ul li a:hover,
.left_question_inner .card .card-heading h4 a i:before,
.testimonials_area .owl-controls .owl-dots .owl-dot:hover,
.testimonials_area .owl-controls .owl-dots .owl-dot.active { */
  background: -webkit-linear-gradient(
    145deg,
    #f4813f 20%,
    #f1556a 60%,
    #f4813f 20%
  );
  background: -moz-linear-gradient(
    145deg,
    #f4813f 20%,
    #f1556a 60%,
    #f4813f 20%
  );
  background: -o-linear-gradient(145deg, #f4813f 20%, #f1556a 60%, #f4813f 20%);
  background: -ms-linear-gradient(
    145deg,
    #f4813f 20%,
    #f1556a 60%,
    #f4813f 20%
  );
  background: linear-gradient(145deg, #f4813f 20%, #f1556a 60%, #f4813f 20%);
}
.gradient-bg {
  background: -webkit-linear-gradient(145deg, #f4813f 0%, #f1556a 100%);
  background: -moz-linear-gradient(145deg, #f4813f 0%, #f1556a 100%);
  background: -o-linear-gradient(145deg, #f4813f 0%, #f1556a 100%);
  background: -ms-linear-gradient(145deg, #f4813f 0%, #f1556a 100%);
  background: linear-gradient(145deg, #f4813f 0%, #f1556a 100%);
}
.gradient-bg3 {
  color: linear-gradient(145deg, #f4813f 0%, #f1556a 100%);
}
h3 {
  font-size: 25px;
  background: -webkit-linear-gradient(#f4813f 30%, #f1556a 70%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
h2 {
  font-size: 23px;
  background: -webkit-linear-gradient(#f4813f 30%, #f1556a 70%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
h1 {
  font-size: 15px;
  background: -webkit-linear-gradient(#f4813f 30%, #f1556a 70%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.maxWidth {
  max-width: 300px !important;
}
.borderSizeNotZero {
  border-radius: 20px !important;
}
.borderSizeZero {
  border-radius: 0px !important;
}
.fontSize {
  font-size: 14px;
}
/* html,
body {
  overflow-y: hidden;
}

.v-main__wrap {
  height: calc(100vh - 65px);
  overflow-y: auto;
  overflow-x: hidden;
}

#content {
  height: calc(100vh - 65px);
  overflow-y: hidden;
} */
</style>
